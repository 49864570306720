import store from "../store";

export default class ClickEventService {
  lifeTimeClicks() {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/click_events/life_time`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": store.getState().authTokenState
      }),
    }).then((res) => {
      return res.json();
    });
  }

  create(params) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/click_events`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then((res) => {
      return res.json();
    });
  }

  links(fromDate, toDate) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/click_events/links?from=${fromDate}&to=${toDate}`, {
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": store.getState().authTokenState
      }),
    }).then((res) => {
      return res.json();
    });
  }

  products(fromDate, toDate) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/click_events/products?from=${fromDate}&to=${toDate}`, {
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": store.getState().authTokenState
      }),
    }).then((res) => {
      return res.json();
    });
  }

  profile(fromDate, toDate) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/click_events/users?from=${fromDate}&to=${toDate}`, {
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": store.getState().authTokenState
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
