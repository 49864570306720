import { useIonRouter, IonInfiniteScroll, IonInfiniteScrollContent, IonFab, IonFabButton, IonContent, IonIcon } from '@ionic/react';
import { addOutline } from 'ionicons/icons';
import store from "../store";
import { ClipLoader } from "react-spinners";
import Card from "./Card";
import ProductService from "../services/ProductService";
import Sheet from 'react-modal-sheet';
import { useState, useEffect } from 'react';

var mobile = require("is-mobile");

const List = (props) => {
  const { products, isLoading, hasMore, loadMore, styles } = props;
  const productService = new ProductService();
  const authToken = store.getState().authTokenState
  const user = store.getState().userState
  const isMobile = mobile();
  const hasProducts = products.length >= 1;
  const cols = isMobile ? [[], []] : [[], [], []];
  const indexCount = isMobile ? 2 : 3;
  const cssColumns = isMobile ? "c1in2" : "c1in3";
  const router = useIonRouter();

  const searchNext = async (event) => {
    await loadMore();
    event.target.complete();
  }

  products.map((product, index) => {
    cols[index % indexCount].push(product);
  });

  const deleteProduct = async (product) => {
    const products = store.getState().productsState
    

    productService.destroy(product.uuid, authToken, user);

    store.dispatch({ 
      type: 'productsState', 
      payload: products.filter(p => p.uuid !== product.uuid) 
    })
  };

  const createProductPage = () => {
    router.push(`/${user.slug}/products/new`)
  }

  return (
    <div>
      {hasProducts && (
        <div>
          <div className="flex justify-between cell-list masonry pr1">
            {cols.map((col, index) => (
              <div className={`${cssColumns} flex flex-column`} key={index}>
                {col.map((product) => (
                  <Card 
                    product={product} 
                    deleteTag={deleteProduct} 
                    styles={styles} 
                  />
                ))}
              </div>
            ))}
          </div>
            
          <IonInfiniteScroll 
            threshold="100px" 
            onIonInfinite={(e) => searchNext(e)}
          >
            <IonInfiniteScrollContent
              class="infinite-scroll-color"
              loadingText="Loading"
            >
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </div>
      )}

      {isLoading && (
        <div className="flex justify-center pb2">
          <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
            <ClipLoader color={"#000000"} loading={isLoading}  />
          </div>
        </div>
      )}

      {!hasProducts && !isLoading && (
        <div className="flex justify-center">
          <div className="bg-white rounded p2 border-full border-grey" >
            <p className="bold center-align">Create your first product</p>
            <div className="flex justify-center">
              <p>Press the red + button at the bottom right</p>
            </div>
          </div>
        </div>
      )}

      {
        authToken && <div className="absolute z3 hover-bottom-right-buttom test-product-creator">
          <IonContent>
            <IonFab slot="fixed" horizontal="end" vertical="bottom" >
              <IonFabButton color="danger">
                <IonIcon onClick={() => createProductPage()} size="large" color="light" ios={addOutline} md={addOutline} />
              </IonFabButton>
            </IonFab>
          </IonContent>
        </div>
      }  
    </div>
  );
}

export default List