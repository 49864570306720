import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useState } from 'react';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticleBar = (props) => {
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      }
    },
  };

  const labels = props.data.map(item => item.date);
  const data = {
    labels: labels,
    datasets: [
      {
        data: props.data.map(item => item.clicks),
        backgroundColor: '#805BEE',
        borderRadius: 10,
      }
    ]
  };

  return (
    <div style={{ height: "350px"}}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default VerticleBar
