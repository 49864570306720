import { IonContent, IonPage } from '@ionic/react';
import LinkList from '../../components/links/LinkList';
import ProfileWrapper from '../../components/users/ProfileWrapper';
import MainHeader from '../../components/headers/main_header';
import store from '../../components/store';
import ClickEventService from '../../components/services/ClickEventService';
import { useEffect } from 'react';

const Links: React.FC = () => {
  const clickEventService = new ClickEventService();
  const user = store.getState().userState
  const authToken = store.getState().authTokenState

  useEffect(() => { createClickEvent() })


  const createClickEvent = () => {
    if (authToken === null) {
      const params = {
        user_uuid: user.uuid,
      };
  
      clickEventService.create(params);
    }
  }

  return (
    <IonPage>
      <MainHeader />
      <IonContent fullscreen>
        <ProfileWrapper 
          isOpen={false}
          styles={store.getState().styles} 
          body={<LinkList styles={ store.getState().styles }/>}
        />
      </IonContent>
    </IonPage>
  );
};

export default Links;
