import { IonContent, IonPage } from '@ionic/react';
import UserProductsWrapper from '../../../components/products/UserProductsWrapper';
import ProfileWrapper from '../../../components/users/ProfileWrapper';
import store from '../../../components/store';

const Products: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <ProfileWrapper 
          styles={ store.getState().styles } 
          body={<UserProductsWrapper />}
        />
      </IonContent>
    </IonPage>
  );
};

export default Products;
