
import store from '../../../store';
import produce from "immer"
import { convertImageUrlToBase64 } from '../../../services/Base64Service'; 

export const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const avatar_base_64 = await convertImageUrlToBase64(value, "avatar")

  const nextState = produce(styles, draftState => {
    draftState["avatar_image_url"] = value
    draftState["avatar_base_64"] = avatar_base_64
    draftState["avatar_css"] = null
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}
