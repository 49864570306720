import Sheet from 'react-modal-sheet';
import EditorList from './EditorList';
import LinkTemplates from './link_editor/Base';
import ProfileEditor from './profile_editor/List';
import BackgroundEditor from './profile_editor/background/Editor';
import HeaderEditor from './profile_editor/header/Editor';
import TabsEditor from './profile_editor/tabs/Editor';
import UsernameEditor from './profile_editor/username/Editor';
import AvatarEditor from './profile_editor/avatar/Editor';
import { useState } from 'react';
import store from '../store';
import produce from "immer"
import { useIonRouter } from '@ionic/react';

const Base = (props) => {
  const baseSnapPoint = [153, 153, 0, 0]
  const baseInitialSnap = 0
  const [currentEditor, setCurrentEditor] = useState("EditorList")
  const [snapPoints, setSnapPoints] = useState(baseSnapPoint)
  const [initialSnap, setInitialSnap] = useState(baseInitialSnap)
  const [profileEditorState, setProfileEditorState] = useState(store.getState().profileEditorState)
  const user = store.getState().userState
  const router = useIonRouter();
  
  store.subscribe(() => {
    const profileEditorState =  store.getState().profileEditorState;
    const currentEditor = profileEditorState.currentEditor === undefined ? "EditorList" : profileEditorState.currentEditor
    const snapPoints = profileEditorState.snapPoints === undefined ? baseSnapPoint : profileEditorState.snapPoints
    const initialSnap = profileEditorState.initialSnap === undefined ? baseInitialSnap : profileEditorState.initialSnap

    setProfileEditorState(profileEditorState)
    setCurrentEditor(currentEditor)
    setSnapPoints(snapPoints)
    setInitialSnap(initialSnap)
  })

  const close = () => {
    router.push(`/${user.slug}`)
    resetCurrentEditor()
    setCurrentEditor("EditorList")
    setSnapPoints(baseSnapPoint)
    setInitialSnap(baseInitialSnap)
  }

  const resetCurrentEditor = () => {
    const nextState = produce(profileEditorState, draftState => {
      draftState['currentEditor'] = "EditorList"
      draftState['snapPoints'] = baseSnapPoint
      draftState['initialSnap'] = baseInitialSnap
      draftState['isOpen'] = false
    })
  
    store.dispatch({ type: 'profileEditorState', payload: nextState })
  }

  const isOpen = () => {
    return window.location.pathname === "/profile_editor"
  }

  return(
    <div>
      <Sheet 
        isOpen={isOpen()} 
        onClose={() => close()} 
        snapPoints={snapPoints}
        initialSnap={initialSnap}
      >
        <Sheet.Container style={{ backgroundColor: "rgb(239 239 239)", boxShadow: "rgb(0 0 0 / 30%) 0px 0px 0px" }}>
          <Sheet.Header />
          <Sheet.Content disableDrag={true}>
            { currentEditor === "EditorList" && <EditorList /> }
            { currentEditor === "ProfileEditor" && <ProfileEditor /> }
            { currentEditor === "LinkTemplates" && <LinkTemplates /> }
            { currentEditor === "BackgroundEditor" && <BackgroundEditor /> }
            { currentEditor === "HeaderEditor" && <HeaderEditor /> }
            { currentEditor === "TabsEditor" && <TabsEditor /> }
            { currentEditor === "UsernameEditor" && <UsernameEditor /> }
            { currentEditor === "AvatarEditor" && <AvatarEditor /> }
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>
    </div>
  )
}

export default Base