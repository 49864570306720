import store from "../store";

export default class UserService {
  show(slug) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/public/users/${slug}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }

  create(params) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }

  update(params) {
    const authToken = store.getState().authTokenState

    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users`, {
      method: "PATCH",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}