import { useIonRouter, IonBackButton, IonTitle, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import ProductService from "../services/ProductService";
import MonetizeUrlService from "../services/MonetizeUrlService";
import { get } from "lodash";
import { ClipLoader } from "react-spinners";
import { useState } from 'react';
import store from "../store";

const EditForm = (props) => {
  const productStore = store.getState().productsState.find(obj => obj.uuid == props.uuid)
  const authToken = store.getState().authTokenState
  const user = store.getState().userState
  const [errors, setErrors] = useState(null);
  const [productUuid, setProductUuid] = useState(props.uuid)
  const [file, setFile] = useState(productStore.image_url)
  const [title, setTitle] = useState(productStore.title)
  const [price, setPrice] = useState(getPrice(productStore.price)) 
  const [redirect_url, setRedirectUrl] = useState(productStore.redirect_url)
  const [promo_code, setPromocode] = useState(productStore.promo_code)
  const [monetizedLink, setMonetizedLink] = useState(null)
  const [rate, setRate] = useState(null)
  const [cpa, setCpa] = useState(null)
  const [cpc, setCpc] = useState(null)
  const [base64Image, setBase64Image] = useState(null)
  const [loading, setLoading] = useState(false)
  const [canMonetizeLink, setCanMonetizeLink] = useState(false)
  const [rates, setRates] = useState(null)
  const [loadingLink, setLoadingLink] = useState(false)
  const [canSearch, setCanSearch] = useState(false)
  const productService = new ProductService();
  const monetizeUrlService = new MonetizeUrlService();
  const [isOpen, setOpen] = useState(false);
  const router = useIonRouter();

  function getPrice(price) {
    if (price != null) {
      return price.substring(1); // Price is $99.99 so we return 99.99
    }
  }

  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    if (name == "title") {
      setTitle(value)
    } else if (name == "price") {
      setPrice(value)
    } else if (name == "promo_code") {
      setPromocode(value)
    }
  };

  const handleUrlChange = async (event) => {
    setRedirectUrl(event.target.value)
    setCanSearch(true)
    setCanMonetizeLink(true)
    setMonetizedLink(true)
  };

  const findMonetizeLink = async (event) => {
    setLoadingLink(true)
    setCanSearch(true)

    const res = await monetizeUrlService.index(redirect_url, authToken);

    if (res.url) {
      setLoadingLink(false)
      setMonetizedLink(res.url)
      setCanMonetizeLink(res.approved)
      setRate(res.rate)
      setCpa(res.cost_per_action)
      setCpa(res.cost_per_action)
      setCpc(res.cost_per_click)
    }
  };

  function useLink() {
    setCanMonetizeLink(false)
    setMonetizedLink(null)
    setRedirectUrl(monetizedLink)
  };

  const handleUpload = async (event) => {
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    const base64Image = await convertToBase64(event.target.files[0]);

    setBase64Image(base64Image)
    setFile(imageUrl)
  };

  const convertToBase64 = async (file) => {
    return new Promise(resolve => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  }

  const update = async () => {
    const params = {
      product: {
        base_64_image: base64Image,
        title: title,
        price: price,
        redirect_url: redirect_url,
        promo_code: promo_code,
      },
    };

    setLoading(true)

    const response = await productService.update(authToken, user.uuid, params);

    if (response.error) {
      setErrors(response.error)
      setLoading(false)
    } else {
      window.location = `/${response.user.slug}/products`;
    }
  };

  const updateProduct = async () => {
    const params = {
      product: {
        title: title,
        price: price,
        redirect_url: redirect_url,
        promo_code: promo_code,
      },
    };

    if (base64Image != undefined) {
      params["product"]["base_64_image"] = base64Image;
    }

    setLoading(true)

    const response = await productService.update(
      authToken,
      user.uuid,
      productUuid,
      params
    );

    if (response.error) {
      setErrors(response.error)
      setLoading(false)
    } else {
      window.location = `/${response.user.slug}/products`;
    }
  };
  
  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" style={{padding: "0px"}} defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonTitle>Edit Product</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container-no-padding max-w600px">
          <div className="p2">
            <div className="pb2">
              <p className="mb0 ">URL</p>
              <input
                type="text"
                name="redirect_url"
                style={{ width: "100%" }}
                value={redirect_url}
                autoFocus
                onChange={(e) => handleUrlChange(e)}
              />
            </div>

            <div className="pb2">
              {/* Uncomment when you want to create the monetize product feature */}
              {/* {canSearch && (
                <div className="bg-blue-xxlight p3 rounded mb2">
                  <div className="flex flex-column center-align">
                    <button
                      className="button button-blue w100 mt1"
                      onClick={() => findMonetizeLink()}
                    >
                      Convert to monetize link
                    </button>
                  </div>
                </div>
              )}
              {loadingLink && (
                <div className="bg-blue-xxlight p3 rounded mb2">
                  <div className="flex flex-column center-align">
                    Creating Monetized Link
                    <div className="center-align pt1">
                      <ClipLoader color={"#000000"} loading={true} />
                    </div>
                  </div>
                </div>
              )}
              {monetizedLink != null && canMonetizeLink && (
                <div className="bg-blue-xxlight p3 rounded mb2">
                  <p className="mb1 ">Yay! This product can be monetized.</p>
                  <div className="flex mb1">
                    <div className="flex">
                      You will earn money:
                      {cpa && (
                        <div className="ml2 text-green  underline-text-green">
                          {rate} Per sale
                        </div>
                      )}
                      {cpc && (
                        <div className="ml2 text-green  underline-text-green">
                          Per Click
                        </div>
                      )}
                    </div>
                  </div>
                  <p className="mb2 text-blue truncate">{monetizedLink}</p>
                  <button
                    className="button button-blue button-small w100"
                    onClick={useLink}
                  >
                    Use monetized link
                  </button>
                  <p className="mt2">Click on Commission Report in navigation to view your monthly payouts.</p>
                </div>
              )}
              {monetizedLink != null && !canMonetizeLink && (
                <div className="bg-brand-tan p3 rounded mb2">
                  <p className="mb1 ">Link can not be monetized</p>
                  <p className="mb1">
                    The brand is not part of our netowrk. You can still create a
                    product using your link.
                  </p>
                </div>
              )} */}
              <p className="mb0">Upload image</p>
              <div>
                <input
                  className="h100px w100 bg-blue-xxlight rounded p2 mb1 border-full border-blue-light"
                  name="file"
                  type="file"
                  onChange={(e) => handleUpload(e)}
                />
              </div>
              <img
                className="flex justify-center max-w150px max-h100px pt1"
                src={file}
              />
            </div>

            <div className="pb2">
              <p className="mb0 ">Title</p>
              <input
                type="text"
                name="title"
                style={{ width: "100%" }}
                value={title}
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            <div className="pb2">
              <p className="mb0 ">Price</p>
              <input
                type="number"
                name="price"
                style={{ width: "100%" }}
                value={price}
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            <div className="pb2">
              <p className="mb0 ">Promo code</p>
              <input
                type="text"
                name="promo_code"
                style={{ width: "100%" }}
                value={promo_code}
                onChange={(e) => handleInputChange(e)}
              />
            </div>

            {errors != null && (
              <div className="px1 border text-red">{errors}</div>
            )}

            <button
              className="button button-large w100 mt2"
              onClick={
                productUuid != undefined ? updateProduct : update
              }
            >
              {loading && <ClipLoader color={"#ffffff"} loading={true} />}
              {!loading && <div className="my1">Save</div>}
            </button>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default EditForm