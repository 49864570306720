import { IonIcon } from '@ionic/react';
import { listOutline, shareSocialOutline, personOutline } from 'ionicons/icons';
import displayEditor from './shared/DisplayEditor';

const EditorList = (props) => {
  return(
    <div className="center">
      <div className="flex justify-center">
        <div className="flex justify-around" style={{ width: "310px"}}>

          <div className="flex flex-column" onClick={() => displayEditor("ProfileEditor", [140, 140, 0, 0], 0)} style={{ width: "90px", height: "60px"}}>
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "25px", height: "25px"}} ios={personOutline} md={personOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Profile</small>
            </div>
          </div>

          <div className="flex flex-column" onClick={() => displayEditor("LinkTemplates", [367, 0, 367, 0], 0)} style={{ width: "90px", height: "60px"}}>
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "25px", height: "25px"}} ios={listOutline} md={listOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Links</small>
            </div>
          </div>
        </div>
      </div>

      <div className="border-top-solid border-grey-light py1">
        <small className="flex justify-center text-grey-xdark py1">Select Editor</small>
      </div>
    </div>
  )
}

export default EditorList
