export default class InstagramService {
  update(authToken, token) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/external_identities/instagram`, {
      method: "PATCH",
      body: JSON.stringify({ token: token }),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}