import React from 'react';
import PropTypes from 'prop-types';
import ProductService from "../services/ProductService";
import List from "./List";
import store from "../store";
import { IonRefresher, IonRefresherContent} from '@ionic/react';

export default class UserProducts extends React.Component {
  static propTypes = {
    authToken: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      products: [],
      hasMore: false,
      isLoading: true
    };


    store.subscribe(() => {
      this.setState({ products: store.getState().productsState })
    })

    this.productService = new ProductService();
    this.loadProducts();
  }

  loadProducts = async () => {
    const authToken = store.getState().authTokenState
    const user = store.getState().userState
    const { page } = this.state;
    const products = store.getState().productsState

    const response = await this.productService.index(
      page,
      authToken,
      user
    );

    const list = response.data.length === 0 ? [] : response.data;

    if(Object.keys(products).length == 0 || page == 1) {
      store.dispatch({ type: 'productsState', payload: list })
    } else {
      list.map((newProduct) => {
        if (products.find(existingProduct => existingProduct.uuid == newProduct.uuid) == undefined) {
          store.dispatch({ type: 'productsState', payload: products.concat(newProduct) })
        }
      })
    }

    this.setState({
      products: store.getState().productsState,
      hasMore: response.has_more,
      isLoading: false
    });
  };

  loadMore = () => {
    const { hasMore } = this.state;
    const products = store.getState().productsState

    if ((products.length > 0) & hasMore) {
      this.setState(
        {
          page: this.state.page + 1,
          isLoading: true
        },
        this.loadProducts
      );
    }
  };

  
  render() {
    const { isLoading, hasMore, products } = this.state;
    const { authToken, styles } = this.props;

    const doRefresh = (event) => {
      this.loadProducts();
      setTimeout(() => {
        event.detail.complete();
      }, 500);
    }

    return (
      <div>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>
        <List
          products={products}
          authToken={authToken}
          isLoading={isLoading}
          hasMore={hasMore}
          loadMore={this.loadMore}
          styles={styles}
        />
      </div>
    );
  }
}
