import { Browser } from '@capacitor/browser';
import { Component } from "react";
import LinksService from "../services/LinksService"
import ClickEventService from "../services/ClickEventService"
import Edit from "./Edit";
import Sheet from 'react-modal-sheet';
import store from '../store';

export default class Link extends Component {
  constructor(props) {
    super(props);

    this.state =  {
      url: props.link.url,
      showNewForm: false,
      showEditForm: false,
      loading: false,
    }

    this.linksService = new LinksService()
    this.clickEventService = new ClickEventService()
  }

  redirect = (link) => {
    if (store.getState().authTokenState !== null) {
      this.clickEventService.create({ link_uuid: link.uuid })
    }

    Browser.open({ url: link.url})
  }

  destroyLink = async () => {
    const { link } = this.props;
    const { authToken, updateLinkList } = this.props;
    
    updateLinkList(link)
    await this.linksService.destroy(link.uuid, authToken);
  }

  toggleMenu() {
    const { showMenu } = this.state;

    this.setState({ showMenu: !showMenu });
  }

  editLink() {
    this.setState({ showMenu: false })
    this.toggleEditForm()
  }

  toggleEditForm = () =>  {
    const { showEditForm } = this.state;

    this.setState({ showEditForm: !showEditForm });
  }

  updateLink = (link) => {
    this.props.link.name = link.name
    this.props.link.url = link.url
  }

  redirectToAnalytics() {
    const { link } = this.props;
    window.location = `/analytics?link_uuid=${link.uuid}`
  }

  handleLoading = (boolean) => {
    this.setState({ loading:  boolean});
  }

  dropdown() {
    const { authToken } = this.props;

    return(
      <div className="flex justify-end">
        {
          authToken && window.location.pathname !== "/profile_editor" &&
          <div
            className="pointer relative"
            onClick={() => this.toggleMenu()}
          >
            <div
              className="bg-white border-full border-grey"
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "27px",
              }}
            >
              <div style={{ position: "absolute", top: "4px", left: "9px" }}>
                <i
                  className="flex justify-center fa fa-ellipsis-v text-black pointer linkMenu-js"
                  style={{ fontSize: "18px" }}
                />
              </div>
            </div>
          </div>
        }
      </div>
    )
  }

  render() {
    const { showMenu, showEditForm } = this.state;
    const { link, authToken, index, styles } = this.props;
    const isUnderlineButton = styles?.button_css?.type === "Underline"

    return(
      <div>
        {
          !isUnderlineButton &&
          <div
            className="flex button button-larger mb2 words-in-div w100" 
            style={ styles.button_css }
          > 
            <div className="w10" />

            <div onClick={() => this.redirect(link)} style={{ width: "80%" }}>
              { link.name }
            </div>

            <div className="w10">
              { this.dropdown() }
            </div>
          </div>
        }

        {
          isUnderlineButton &&
          <div>
            <div
              className="flex justify-between relative button button-larger mb2 words-in-div w100" 
              style={ styles.button_css }
              >
              <div onClick={() => this.redirect(link)}>
                { link.name }
              </div>
              <div className="flex">
                <p>{ index }</p>
                { this.dropdown() }
              </div>
            </div>
          </div>
        }

        { showMenu && (
          <div className="relative z3 linkMenu-js">
            <div className="absolute right-0" style={{ top: "-29px" }}>
              <div
                className="bg-white rounded mt2 mr1 py2 px4 z3 border-full border-grey mb4 w300px mr1"
              >
                <p className="text-black pointer" onClick={() => this.editLink()}>
                  Edit
                </p>
                <p className="text-black pointer" onClick={() => this.destroyLink()}>
                  Delete
                </p>
              </div>
            </div>
          </div>
        )}

        {
          showEditForm && 
          <Sheet 
            isOpen={showEditForm} 
            onClose={() => this.toggleEditForm()} 
            snapPoints={[-50, 0.5, 100, 0]}
          >
            <Sheet.Container >
              <Sheet.Header />
              <Sheet.Content>
                <Edit link={link} authToken={authToken} toggleEditForm={this.toggleEditForm} updateLink={this.updateLink} handleLoading={this.handleLoading}/>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        }
      </div>
    )
  }
}