import store from "../../../store";
import produce from "immer";

const currentLinkEditor = (currentLinkEditor) => {
  const profileEditorState = store.getState().profileEditorState

  const nextState = produce(profileEditorState, draftState => {
    draftState['currentLinkEditor'] = currentLinkEditor
  })

  store.dispatch({ type: 'profileEditorState', payload: nextState })
}

export default currentLinkEditor