import { useState, useEffect } from 'react';
import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel } from '@ionic/react';
import { } from "../../images/instagram.svg";
import UserService from '../../components/services/UserService';
import store from "../../components/store";
import { BrowserRouter as Router, Link } from "react-router-dom"

const Signup = () => {
  const userService = new UserService()
  const router = useIonRouter();
  const [username, setUsername] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")
  const [country, setCountry] = useState("AU")
  const [errors, setErrors] = useState([])

  const createUser = async () => {
    const res  = await userService.create(
      { 
        user: {
          username: username, 
          email: email, 
          country: country, 
          password: password,
          password_confirmation: passwordConfirmation
        }
      }
    )

    if(res.errors) {
      setErrors(res.errors)
    } else {
      router.push(`/confirm_email`);
    }
  }

  return (
    <IonPage>
      <IonContent>
        <div className="flex-auto">
          <div className="flex justify-center pt3">
            <Link to={`/login`}>
              <img className="max-w250px" src="assets/images/logo_black.png" />
            </Link>
          </div>
          <div className="flex justify-center">
            <div className="form-half p2 max-w500px">
              <div className="bg-white rounded-large">
                <div className="flex flex-column px4">
                  <h1 className="font-brand text-brand-blue-dark">Sign up</h1>
                  <form novalidate="novalidate" class="simple_form new_user">
                    <div className="form-inputs">
                      <div className="mb2">
                        <label class="control-label">Username</label>
                        <input 
                          type="text"
                          name="username"
                          autoFocus
                          onChange={(e) => setUsername(e.target.value)} 
                        />
                      </div>

                      <div className="mb2">
                        <label class="control-label">Email</label>
                        <input class="form-control password optional" type="email" onChange={(e) => setEmail(e.target.value)} />
                      </div>

                      <div className="mb2">
                        <label class="control-label">Password</label>
                        <input class="form-control password optional" type="password" onChange={(e) => setPassword(e.target.value)} />
                      </div>

                      <div className="mb2">
                        <label class="control-label">Password Confirmation</label>
                        <input class="form-control password optional" type="password" onChange={(e) => setPasswordConfirmation(e.target.value)} />
                      </div>
                    </div>
                  </form>
                  <button className="button button-brand-brown w100 mt2" onClick={(e) => createUser()}>Sign up</button>
                  {
                    errors.length > 0 && <div className="flex items-baseline border-full border-solid rounded mt3 border-red">
                      <p className="text-red pt2 px2">{errors}</p>
                    </div>
                  }

                  <div className="pt3">
                    <span class="font-11 pb4">
                      <span className="pr1">By signing up, you agree to ShopMyFeed's</span>
                      <div className="flex">
                        <Link to={"/pages/terms_and_conditions"}>
                          <p className="text-blue pr1">Terms of Services</p>
                        </Link>
                        <span className="pr1">and</span>
                        <Link to={"/pages/privacy_policy"}>
                          <p className="text-blue">Privacy Policy</p>
                        </Link>
                      </div>
                    </span>
                  </div>

                  <div className="pt3 flex">
                    <p className="pr1">Already have an account?</p>
                    <Link to={`/login`}>
                      <p className="text-blue">Log in</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Signup;
