import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import PublicRoutes from './routes/PublicRoutes';
import AuthenticatedRoutes from './routes/AuthenticatedRoutes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
import store from "./components/store";

/* Theme variables */
import './theme/variables.css';
import { useState } from 'react';
import { persistStore } from 'redux-persist';

/* Deep linking */
import AppUrlListener from './components/deep_linking/AppUrlListener';

const persistor = persistStore(store)

const App: React.FC = () => {
  const [authToken, setAuthToken] = useState(store.getState().authTokenState)

  store.subscribe(() => {
    setAuthToken(store.getState().authTokenState)
  })

  const loggedout = (authToken === null) || (Object.keys(authToken).length === 0)
  
  return (
    <IonApp>
      <IonReactRouter>
        <AppUrlListener></AppUrlListener>

        {
          loggedout && <PublicRoutes />
        }

        {
          !loggedout &&  <AuthenticatedRoutes />
        }
      </IonReactRouter>
    </IonApp>
  )
};

export default App;
