import store from "../store";
import { Link } from 'react-router-dom';
import { useIonRouter } from "@ionic/react";
import { useState } from 'react';

const Media = (props) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [media, setMedia] = useState(props.media);
  const [products, setProducts] = useState(props.media.products);

  store.subscribe(() =>
    resetMediaAndProduct()
  )

  function resetMediaAndProduct() {
    const media = store.getState().mediasState.find(obj => obj.id == props.media.id)
    if (media !== undefined) {
      setMedia(media)
      setProducts(media.products)
    }
  }


  const closeModal = () => {
    const { user } = props;
    setModalIsOpen(false);
    window.history.pushState({ id: "homepage" }, "Taddar", `/${user.slug}`);
  };

  const addMediaProduct = (product) => {
    const list = products.concat(product);
    setProducts(list);
  };

  const removeMediaProduct = (product) => {
    setProducts(products.filter((p) => p.uuid != product.uuid))
  };

  return (
    <div>
      <Link to={`/medias/${media.id}`}>
        <div className="card-image-container relative spec-instagram-image">
          <div className="card-image">
            <div className="relative">
              {products.map((product, index) => (
                <div
                  className={`absolute z1 dot-number-white-small`}
                  style={{
                    display: "inline-block",
                    top: `calc(${product.top}% - 8px )`,
                    left: `calc(${product.left}% - 8px)`,
                    boxShadow: "rgb(149 149 149 / 65%) 0px 0px 1px 9px",
                  }}
                ></div>
              ))}
              <img
                className="w100 pl1 pr0.5"
                src={media.img_path}
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default Media;