import { IonPage, IonContent, IonRefresher, IonRefresherContent, IonToolbar, IonButtons, IonHeader } from '@ionic/react';
import VerticleBar from "./charts/VerticleBar";
import { useState, useEffect } from 'react';
import ClickEventService from './services/ClickEventService';
import RankList from './analytics/RankList';
import { ClipLoader } from "react-spinners";
import MainHeader from './headers/main_header';

const Analytics = (props) => {
  const moment = require('moment')
  const clickEventService = new ClickEventService()
  const border = { borderRight: "1px solid white" };
  const [lifeTimeProfileClicks, setLifeTimeProfileClicks] = useState(null);
  const [lifeTimeProductClicks, setLifeTimeProductClicks] = useState(null);
  const [lifeTimeLinkClicks, setLifeTimeLinkClicks] = useState(null);
  const [currentType, setCurrentType] = useState("links");
  const [currentDays, setCurrentDays] = useState(7);
  const [currentAnalytics, setCurrentAnalytics] = useState(null);
  const [currentRank, setCurrentRank] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => { 
    if (currentAnalytics == null) {
      fetchAll()
    }
  })

  const fetchAll = async () => {
    setLoading(true)
    fetchLifeTimeClicks()
    fetchAnalytics(currentType, currentDays)
  }

  const fetchLifeTimeClicks = async () => {
    const response = await clickEventService.lifeTimeClicks()
    setLifeTimeProfileClicks(response.life_time_user_clicks)
    setLifeTimeProductClicks(response.life_time_product_clicks)
    setLifeTimeLinkClicks(response.life_time_link_clicks)
    setLoading(false)
  }

  const fetchLinks = async (fromDate, toDate) => {
    const response = await clickEventService.links(fromDate, toDate)
    setCurrentAnalytics(response.analytics)
    setCurrentRank(response.rank)
  }

  const fetchProducts = async (fromDate, toDate) => {
    const response = await clickEventService.products(fromDate, toDate)
    setCurrentAnalytics(response.analytics)
    setCurrentRank(response.rank)
  }

  const fetchProfile = async (fromDate, toDate) => {
    const response = await clickEventService.profile(fromDate, toDate)
    setCurrentAnalytics(response.analytics)
    setCurrentRank(response.rank)
  }

  const fetchAnalytics = (type, days=currentDays) => {
    setCurrentType(type)
    setCurrentDays(days)

    const startDate = fromDate(days)
    const endDate = toDate()

    if(type === "links") {
      fetchLinks(startDate, endDate)
    } else if(type === "products") {
      fetchProducts(startDate, endDate)
    } else if(type === "profile") {
      fetchProfile(startDate, endDate)
    }
  }

  const toDate = () => {
    const date = moment()
    return moment.utc(date.format()).format()
  }

  const fromDate = (days) => {
    const date = moment().subtract(days, 'd')
    return moment.utc(date.format()).format()
  }

  const updateCurrentDays = async (days) => {
    await setCurrentDays(days)
    fetchAnalytics(currentType, days)
  }

  const doRefresh = (event) => {
    fetchAll()

    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  const dropDownStyles = { border: "none", width: "auto", fontSize: "1.7rem", fontFamily: "Aleo" }

  return (
    <IonPage className="container-no-padding max-w800px">
      <MainHeader displayOnlyHamburger={true}/>
      <IonContent fullscreen>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <div className="px3">
          <div className="bg-black rounded-large px3 pt1 pb4 mt3">
            <h6 className="text-purple font-aleo">Life time clicks</h6>
            <div className="flex">
              <div className="c1in3" style={border}>
                <p className="center-align pb0 text-brand-green">Profile</p>
                <h5 className="center-align my0 bold text-white">{lifeTimeProfileClicks}</h5>
              </div>
              <div className="c1in3" style={border}>
                <p className="center-align pb0 text-brand-green">Links</p>
                <h5 className="center-align my0 bold text-white">{lifeTimeLinkClicks}</h5>
              </div>
              <div className="c1in3">
                <p className="center-align pb0 text-brand-green">Products</p>
                <h5 className="center-align my0 bold text-white">{lifeTimeProductClicks}</h5>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt3">
            <div>
              <select className="bold" onChange={(event) => { fetchAnalytics(event.target.value) }} style={{ ...dropDownStyles, fontSize: "22px" }}>
                <option value="links">Links</option>
                <option value="products">Products</option>
                <option value="profile">Profile</option>
              </select>
            </div>

            <div>
              <select onChange={(event) => { updateCurrentDays(event.target.value) }} style={dropDownStyles}>
                <option value="7">Last 7 days</option>
                <option value="28">Last 4 weeks</option>
                <option value="84">Last 3 months</option>
                <option value="168">Last 6 months</option>
              </select>
            </div>
          </div>

          { loading === true && 
            <div className="flex justify-center pb2">
              <div className="bg-white p1" style={{ borderRadius: "60px", display: "inherit" }}>
                <ClipLoader color={"black"} loading={true}  />
              </div>
            </div>
          }
          
          {
            loading === false && currentAnalytics !== null && 
            <div className="p2 rounded-large" style={{ backgroundColor: "#f7f7f7" }}>
              <VerticleBar data={currentAnalytics}/>
            </div>
          }

          {
            currentType !== "profile" && loading === false && <div className="pb3">
              <h5 className="font-aleo bold mt5">Rankings</h5>
              <div className="p2 rounded-large" style={{ backgroundColor: "#f7f7f7" }}>
                {
                  currentRank !== null && currentRank !== undefined && <RankList ranks={currentRank}/>
                }
              </div>
            </div>
          }
        </div>
      </IonContent>
    </IonPage>
  );
}

export default Analytics
