import { useIonRouter } from '@ionic/react';
import copy from "copy-to-clipboard";
import ClickEventService from "../services/ClickEventService";
import InstagraProductService from "../services/InstagramPostProductsService";
import store from "../store";
import { useState } from 'react';

const Card = (props) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  const [openPageInSeconds, setOpenPageInSeconds] = useState(3);
  const { product, index, styles, deleteTag } = props;
  const backgroundColor = styles == undefined ? "white" : styles.button_css.backgroundColor
  const buttonTextColour = styles == undefined ? "black" : styles.button_css.color
  const border = styles == undefined ? "1px solid #cccccc" : styles.button_css.border
  const user = store.getState().userState
  const authToken = store.getState().authTokenState
  const isProfileUser = authToken !== null
  const instagramPostProductService = new InstagraProductService();
  const clickEventService = new ClickEventService();
  const router = useIonRouter();

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const editUrl = (product) => {
    router.push(`/${user.slug}/products/${product.uuid}/edit`)
  }

  const createClickEvent = () => {
    if (authToken !== null) {
      const params = {
        product_uuid: product.uuid,
      };
  
      clickEventService.create(params);
    }
  }

  const redirectToBrandsProductPage = (product_url) => {
    createClickEvent();

    if (product.promo_code != null) {
      copy(product.promo_code);

      setShowRedirectMessage(true)

      setTimeout(() => {
        setOpenPageInSeconds(2)
      }, 1000);

      setTimeout(() => {
        setOpenPageInSeconds(1)
      }, 2000);

      setTimeout(() => {
        setShowRedirectMessage(false)
        setOpenPageInSeconds(0)
        window.location = product_url
      }, 3000);
    } else {
      window.location = product_url;
    }
  }

  return (
    <div className="px1" key={product.uuid} id={`product-${product.uuid}`}>
      { showRedirectMessage && (
        <div
          className="fixed-position w100 z5 bg-black p2 large-shadow"
          style={{ top: "0px", left: "0px" }}
        >
          <i className="relative fa fa-4x pb2 white fa-copy flex justify-center" />
          <h1 className="text-white center-align bold">Hang Tight</h1>
          <p className="text-white center-align">{`Promo code ${product.promo_code} copied to clipboard`}</p>
          <p className="text-white center-align">{`Redirecting in ${openPageInSeconds} seconds`}</p>
        </div>
      )}

      <div className="card pointer flex flex-column relative mb2 w100 test-card" style={{ backgroundColor: backgroundColor, border: border }}>
        { isProfileUser && (
          <div className="absolute pointer r0" id="toggle-product-menu">
            <div
              className="relative z2 pr1 pt1"
              onClick={() => toggleMenu()}
            >
              <div
                className="bg-white"
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "12px",
                }}
              >
                <i
                  className="fa fa-ellipsis-v text-grey-xdark pointer pl2"
                  style={{ paddingTop: "4px", paddingLeft: "10px" }}
                />
              </div>
              { showMenu && (
                <div
                  className="absolute bg-white rounded p2 mt1"
                  style={{ right: "4px" }}
                >
                  <p onClick={() => editUrl(product)}>Edit</p>
                  <p onClick={() => deleteTag(product)} id="delete">
                    Delete
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
        <a onClick={() => redirectToBrandsProductPage(product.redirect_url)} target="_blank">
          <img className="rounded-top w100" src={product.medium_image_url} />
          <div className="px2 pb2">
            <p className="mt1" style={{ color: buttonTextColour }}>
              {index == undefined
                ? product.title
                : `${index}) ${product.title}`}
            </p>
            <p className="mt1" style={{ color: buttonTextColour }}>
              {product.price} {product.currency_code}
            </p>
            {product.promo_code && (
              <div className="flex font-smaller mt1" style={{ color: buttonTextColour }}>
                <div className="bg-brand-tan px2 border-grey rounded">
                  {`Promo: ${product.promo_code}`}
                </div>
              </div>
            )}
          </div>
        </a>
      </div>
    </div>
  );
}

export default Card