import Base from './Base';

const OutlineTransparent = (props) => {
  return(
    <Base 
      params={{ 
        color: "black", 
        border: "solid black",
      }}
    />
  )
}

export default OutlineTransparent
