import Colors from '../../shared/Colors';
import ColorPicker from '../../shared/ColorPicker';
import Gradient from '../../shared/Gradient';
import Image from '../../shared/Image';
import { useState } from 'react';
import { updateColor } from './UpdateColor';
import { updateGradient, updateRadius } from './UpdateGradient';
import { updateStore } from './UpdateImage';
import store from '../../../store';
import Footer from '../../Footer';
import displayEditor from '../../shared/DisplayEditor';

const Editor = (props) => {
  const profileSettingChangesState = store.getState().profileSettingChangesState
  const [currentTab, setCurrentTab] = useState("colorTab")

  const underLineTab = (tab) => {
    if (currentTab === tab) {
      return { borderBottomWidth: "1px", borderBottomStyle: "solid" } 
    } else {
      return {}
    } 
  }

  const imageUrls = [
    "assets/images/backgrounds/background_1.jpeg",
    "assets/images/backgrounds/background_2.jpg",
    "assets/images/backgrounds/background_3.jpg",
    "assets/images/backgrounds/background_4.jpg",
    "assets/images/backgrounds/background_5.jpg",
    "assets/images/backgrounds/background_6.jpg",
    "assets/images/backgrounds/background_7.jpg",
    "assets/images/backgrounds/background_8.jpeg",
  ]

  return(
    <div className="center">
      <div className="flex justify-center border-bottom-solid border-grey">
        <div className="flex justify-around w100">
          <div className="w25" style={underLineTab("colorTab")} onClick={() => setCurrentTab("colorTab")}>
            <small className="text-grey-xdark flex justify-center">Color</small>
          </div>
          <div className="w25" style={underLineTab("colorPickerTab")} onClick={() => setCurrentTab("colorPickerTab")}>
            <small className="text-grey-xdark flex justify-center">Picker</small>
          </div>
          <div className="w25" style={underLineTab("grandientTab")} onClick={() => setCurrentTab("grandientTab")}>
            <small className="text-grey-xdark flex justify-center">Gradient</small>
          </div>
          <div className="w25" style={underLineTab("imageTab")} onClick={() => setCurrentTab("imageTab")}>
            <small className="text-grey-xdark flex justify-center">Image</small>
          </div>
        </div>
      </div>

      { currentTab === "colorTab" && <Colors updateService={updateColor} /> }
      { currentTab === "colorPickerTab" && <ColorPicker updateService={updateColor} defaultColor={profileSettingChangesState.background_css?.backgroundColor}/> }
      { currentTab === "grandientTab" && <Gradient updateGradient={updateGradient} updateRadius={updateRadius}/> }
      { currentTab === "imageTab" && 
        <div className="center pt2 profile-editor-tabs">
          <Image storeAttribute="background_image_url" updateStore={updateStore} images={imageUrls}/> 
        </div>
      }

      <Footer back={() => displayEditor("ProfileEditor", [140, 0.5, 140, 0], 0)} name="Background Editor" reset={false}/>
    </div>
  )
}

export default Editor
