import store from '../../../store';
import produce from "immer"

export const updateColor = async (color) => {
  const styles = store.getState().profileSettingChangesState
  const params = {}
  const usernameCss = { ...styles.username_css, color: color }
  params["username_css"] = usernameCss

  updateStore(usernameCss)
}

const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const nextState = produce(styles, draftState => {
    draftState['username_css'] = value
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}
