import React from 'react';
import produce from "immer"
import { IonIcon, IonButtons, IonToolbar, IonHeader } from '@ionic/react';
import { closeOutline, returnUpBackOutline } from 'ionicons/icons';
import { IonContent, IonPage } from '@ionic/react';
import ProfileWrapper from '../../components/users/ProfileWrapper';
import ProfileEditorBase from '../../components/editors/Base';
import store from '../../components/store';
import { useIonRouter } from '@ionic/react';
import ProfileSettingService from '../../components/services/ProfileSetting';
import { useState, useEffect } from 'react';
import { ClipLoader } from "react-spinners";
import LinkList from '../../components/links/LinkList';
const profileSettingService = new ProfileSettingService();;

const Editor: React.FC = () => {
  const router = useIonRouter();
  const user = store.getState().userState
  const [isSaving, setIsSaving] = useState(false)
  const [buttonTitle, setButtonTitle] = useState("PUBLISH")
  const authToken = store.getState().authTokenState

  useEffect(() => {
    setProfileSettingChangesState()
  })
  
  const setProfileSettingChangesState = () => {
    if(window.location.pathname === "/profile_editor" && Object.keys(store.getState().profileSettingChangesState).length === 0) {
      const currentStyle = store.getState().styles
      store.dispatch({ type: 'profileSettingChangesState', payload: currentStyle })
    }
  }

  const close = () => {
    setIsSaving(false)
    router.push(`/${user.slug}`)

    const profileEditorState = store.getState().profileEditorState

    const nextState = produce(profileEditorState, draftState => {
      draftState['isOpen'] = false
    })
  
    store.dispatch({ type: 'profileEditorState', payload: nextState })
  }

  const saveProfile = async () => {
    const styles = store.getState().profileSettingChangesState
    setIsSaving(true)

    const res = await profileSettingService.update(
      authToken, 
      { profile_setting: styles, uuid: styles.uuid }
    )

    store.dispatch({ type: 'stylesState', payload: res })
    store.dispatch({ type: 'profileSettingChangesState', payload: res })
    setIsSaving(false)
    savedTitle()
    router.push(`/${user.slug}`)
  }

  const savedTitle = () => {
    setButtonTitle("SAVED");
    setTimeout(() => {
      setButtonTitle("PUBLISH")
    }, 2000);
  }

  const resetToLastSave = async () => {
    const styles = store.getState().styles
    const res = await profileSettingService.show(authToken, { uuid: styles.uuid })
    store.dispatch({ type: 'profileSettingChangesState', payload: res })
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonIcon 
              style={{ fontSize: "23px" }}  ios={closeOutline} md={closeOutline} onClick={() => close()} />
          </IonButtons>
          <IonButtons slot="end">
            <div className="flex items-center">
              <IonIcon className ="pr2" style={{ fontSize: "23px" }} ios={returnUpBackOutline} md={returnUpBackOutline} onClick={() => resetToLastSave()}/>
              <button className="button button-black button-small mr1 w100px" style={{ height: "28px" }} onClick={() => saveProfile()}>
                { !isSaving && <p className="mb0">{buttonTitle}</p> }
                { 
                  isSaving && 
                  <div className="flex justify-center relative" style={{ top: "-2px" }}>
                    <ClipLoader color={"white"} size={20} loading={true}  />
                  </div>
                }
              </button>
            </div>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <ProfileWrapper 
          isOpen={true}
          styles={store.getState().profileSettingChangesState}
          body={<LinkList styles={store.getState().profileSettingChangesState}/>} 
        />
        <ProfileEditorBase />
      </IonContent>
    </IonPage>
  );
};

export default Editor;
