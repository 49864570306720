import { useState, useEffect } from 'react';
import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel } from '@ionic/react';
import { } from "../../images/instagram.svg";
import ConfirmEmailService from '../../components/services/ConfirmEmailService';
import store from "../../components/store";
import { BrowserRouter as Router, Link } from "react-router-dom"

const ConfirmEmail = () => {
  const confirmEmailService = new ConfirmEmailService()
  const router = useIonRouter();
  const [errors, setErrors] = useState([])

  const inputPinCode = async (pinCode) => {
    pinCode.length == 6 && confirmEmail(pinCode)
  }
  
  const confirmEmail = async (pinCode) => {
    const res  = await confirmEmailService.update(pinCode)

    if(res.errors) {
      setErrors(res.errors)
    } else {
      await store.dispatch({ type: 'stylesState', payload: res.styles })
      await store.dispatch({ type: 'userState', payload: res.user })
      await store.dispatch({ type: 'authTokenState', payload: res.auth_token })
      router.push(`/${res.user.slug}`);
    }
  }

  return (
    <IonPage>
      <div>
        <div className="flex-auto">
          <div className="flex justify-center pt3">
            <Link to={`/login`}>
              <img className="max-w250px" src="assets/images/logo_black.png" />
            </Link>
          </div>
          <div className="flex justify-center">
            <div className="form-half p2 max-w500px">
              <div className="bg-white rounded-large">
                <div className="flex flex-column p4">
                  <h1 className="font-brand text-brand-brown">Confirm email</h1>
                  <p>Input 6 digit pin sent to your email account.</p>
                  <form novalidate="novalidate" class="simple_form new_user">
                    <div className="form-inputs">
                      <div className="mb2">
                        <input class="form-control password optional font-xlarge" type="text" onChange={(e) => inputPinCode(e.target.value)} />
                      </div>
                    </div>
                  </form>
                  
                  {
                    errors.length > 0 && <div className="flex items-baseline border-full border-solid rounded mt3 border-red">
                      <p className="text-red pt2 px2">{errors}</p>
                    </div>
                  }

                  <div className="pt3 flex">
                    <Link to={`/resend_confirm_email`}>
                      <p className="text-blue">Resend email confirmation code?</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default ConfirmEmail;
