export default class ResetPasswordService {
  send_email(email) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/password`, {
      method: "POST",
      body: JSON.stringify({ email: email, redirect_url: "www.google.com" }),
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }

  resend(email) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/resend_email_confirmation`, {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }

  update(password, passwordConfirmation, token) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/password`, {
      method: "PUT",
      body: JSON.stringify({ 
        password: password,
        password_confirmation: passwordConfirmation,
        reset_password_token: token 
      }),
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }
}