export const imageUrlToFile = async (imgUrl, fileName) => {
  var imgExt = getUrlExtension(imgUrl);

  const response = await fetch(imgUrl);
  const blob = await response.blob();
  return new File([blob], `${fileName}.` + imgExt, {
    type: blob.type,
  });
}

const getUrlExtension = (url) => {
  return url
    .split(/[#?]/)[0]
    .split(".")
    .pop()
    .trim();
}

export const convertToBase64 = async (file) => {
  return new Promise(resolve => {
    let baseURL = "";
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
}

export const convertImageUrlToBase64 = async (imgUrl, fileName) => {
  const file = await imageUrlToFile(imgUrl,  fileName)
  return convertToBase64(file);
}