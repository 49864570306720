import { IonIcon } from '@ionic/react';
import { cloudUploadOutline } from 'ionicons/icons';

const Image = (props) => {
  const handleUpload = async (event) => {
    const imageUrl = URL.createObjectURL(event.target.files[0]);
    props.updateStore(imageUrl)
  };

  const imageSelect = async (event) => {
    props.updateStore(event.target.currentSrc)
  };

  return (
    <div>
      <label for="file-upload" className="flex flex-column justify-center bg-white py3 rounded mb1">
        <IonIcon className="center" style={{ color: "grey", width: "25px", height: "25px" }} ios={cloudUploadOutline} md={cloudUploadOutline} />
        <p className="center">Upload image</p>
      </label>
      <input
        id="file-upload"
        style={{ display: "none" }}
        name="file"
        type="file"
        onChange={(e) => handleUpload(e)}
      />

      {
        props.images?.map((imageUrl) =>
          <img className="m1 mx3" style={{ width: "38px" }} onClick={(e) => imageSelect(e)} src={imageUrl}></img>
        )
      }
    </div>
  )
}

export default Image
