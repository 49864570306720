import React, { Component } from "react";
import { PropTypes } from "prop-types";

export default class TagCard extends React.Component {
  static propTypes = {
    product: PropTypes.object,
    media: PropTypes.object,
    authToken: PropTypes.object,
  };

  render() {
    const { product, selectProduct } = this.props;

    return (
      <div
        className="px1 pointer"
        key={product.uuid}
        id={`product-${product.uuid}`}
        onClick={() => selectProduct(product)}
      >
        <div className="card flex flex-column relative bg-white rounded-large mb4 border-light-grey">
          <div className="card-image-container relative">
            <div className="card-image">
              <img src={product.medium_image_url} />
            </div>
          </div>
          <div className="px2 pb1">
            <div className="font-smaller text-black mt1">{product.title}</div>
            <div className="font-small bold text-black mt1">
              {product.price} {product.currency_code}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
