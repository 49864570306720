export default class InstagramPostService {
  index(nextMaxId, authToken, user) {
    const max_id = nextMaxId != null ? `max_id=${nextMaxId}` : null;

    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${user.uuid}/instagram_posts?${max_id}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
