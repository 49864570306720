import store from '../../../store';
import produce from "immer"

export const updateGradient = async (gradient) => {
  const css = { 
    background: `linear-gradient(0deg, ${gradient.colour_1} 0%, ${gradient.colour_2} 100%)`, 
  }
    
  updateStore(css)
}

export const updateRadius = async (radius, colour1, colour2) => {
  const css = { 
    background: `linear-gradient(${radius}, ${colour1} 0%, ${colour2} 100%)`
  }
  
  updateStore(css)
}


const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const nextState = produce(styles, draftState => {
    draftState['header_image_url'] = null
    draftState['header_background_css'] = value
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}