import store from '../../../store';
import produce from "immer"

export const updateColor = async (color) => {
  updateStore(color)
}

const updateStore = async (color) => {
  const styles = store.getState().profileSettingChangesState
  const nextState = produce(styles, draftState => {
    delete draftState["button_css"]["background"] 
    draftState["button_css"]["backgroundColor"] = color
  })

  store.dispatch({ type: "profileSettingChangesState", payload: nextState })
}
