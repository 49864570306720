import { useState } from 'react';

const Gradient = (props) => {
  const [colour1, setColour1] = useState("#000000")
  const [colour2, setColour2] = useState("#ffffff")
  const [radius, setRadius] = useState("0deg")

  const dotCss = (gradient) => {
    return {
      height: "44px",
      width: "44px",
      background: `linear-gradient(0deg, ${gradient.colour_1} 0%, ${gradient.colour_2} 100%)`,
      borderRadius: "50%",
      display: "inline-block",
      margin: "0.3rem",
      border: "white solid 1px"
    }
  }

  const gradients =[
    { colour_1: "#ff512f", colour_2: "#dd2476" }, 
    { colour_1: "#eb3349", colour_2: "#f45c43" },
    { colour_1: "#7b4397", colour_2: "#dc2430" },
    { colour_1: "#cc2b5e", colour_2: "#753a88" },
    { colour_1: "#ee9ca7", colour_2: "#ffdde1" },
    { colour_1: "#ddd6f3", colour_2: "#faaca8" },
    { colour_1: "#ffafbd", colour_2: "#ffc3a0" },
    { colour_1: "#dd5e89", colour_2: "#f7bb97" },
    { colour_1: "#de6262", colour_2: "#ffb88c" },
    { colour_1: "#eecda3", colour_2: "#ef629f" },
    { colour_1: "#eacda3", colour_2: "#d6ae7b" },
    { colour_1: "#d66d75", colour_2: "#e29587" },
    { colour_1: "#56ab2f", colour_2: "#a8e063" },
    { colour_1: "#06beb6", colour_2: "#48b1bf" },
    { colour_1: "#2193b0", colour_2: "#6dd5ed" },
    { colour_1: "#02aab0", colour_2: "#00cdac" },
    { colour_1: "#43cea2", colour_2: "#185a9d" },
    { colour_1: "#614385", colour_2: "#516395" },
    { colour_1: "#42275a", colour_2: "#734b6d" },
    { colour_1: "#000428", colour_2: "#004e92" },
    { colour_1: "#b2b2b2", colour_2: "#ffffff" },
  ]

  return(
    <div className="center pt1 profile-editor-tabs">
      <div className="flex pb2 pt1">
        <div className="pl1 pr1" style={{ width: "33%" }}>
          <div className="mb0 uppercase font-smaller">Color 1</div>
          <input
            type="text"
            name="redirect_url"
            className="w100"
            value={colour1}
            onChange={(event) => {
              setColour1(event.target.value)
              props.updateGradient({ colour_1: event.target.value, colour_2: colour2 })
            }}
          />
        </div>

        <div className="pr1" style={{ width: "33%" }}>
          <div className="mb0 uppercase font-smaller">Color 2</div>
          <input
            type="text"
            name="redirect_url"
            className="w100"
            value={colour2}
            onChange={(event) => {
              setColour2(event.target.value)
              props.updateGradient({ colour_1: colour1, colour_2: event.target.value })
            }}
          />
        </div>

        <div style={{ width: "33%" }}>
          <div className="mb0 uppercase font-smaller">Radius</div>
          <select 
            className="w100" 
            onChange={(event) => {
              setRadius(event.target.value)
              props.updateRadius(event.target.value, colour1, colour2)
            }}
          >
            <option value="0deg">0 deg</option>
            <option value="45deg">45 deg</option>
            <option value="180deg">180 deg</option>
          </select>
        </div>
      </div>

      {
        gradients.map((gradient, i) => 
          <div 
            className={i} 
            style={dotCss(gradient)} 
            onClick={() => {
              setColour1(gradient.colour_1)
              setColour2(gradient.colour_2)
              props.updateGradient(gradient)
            }}
          />
        )
      }
    </div>
  )
}

export default Gradient
