
import store from '../../../store';
import produce from "immer"
import { convertImageUrlToBase64 } from '../../../services/Base64Service'; 

export const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const header_base_64 = await convertImageUrlToBase64(value, "header")
  
  const nextState = produce(styles, draftState => {
    draftState["header_background_css"] = null
    draftState["header_image_url"] = value
    draftState["header_base_64"] = header_base_64
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}