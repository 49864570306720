import ProfileSettingService from '../../services/ProfileSetting';
import store from '../../store';
import Footer from '../Footer';
import OutLineTransperant from './types/OutlineTransparent';
import Standard from './types/Standard';
import Underline from './types/Underline';
import Shadow from './types/Shadow';
import displayEditor from '../shared/DisplayEditor';

const List = (props) => {
  const templates = [
    <Standard />,
    <OutLineTransperant />,
    <Shadow />,
    <Underline />,
  ]

  const cols = [[], []];

  templates.map((product, index) => {
    cols[index % 2].push(product);
  });

  return(
    <div>
      <div className="px2">
        <div className="flex justify-between cell-list masonry pr1" style={{ height: "287px", overflowX: "auto" }}>
          { cols.map((col, index) => (
            <div className={`c1in2 flex flex-column`} key={index}>
              { col }
            </div>
          ))}
        </div>
      </div>

      <Footer 
        back={() => displayEditor("EditorList", [140, 0.5, 140, 0], 0)} 
        name="Select Link Style" reset={false}
      />
    </div>
  )
}

export default List
