import { IonIcon } from '@ionic/react';
import { createOutline, textOutline } from 'ionicons/icons';
import Footer from '../Footer';
import displayEditor from '../shared/DisplayEditor';
import currentLinkEditor from './shared/CurrentLinkEditor';

const EditorTypes = (props) => {
  return(
    <div className="center">
      <div className="flex justify-center">
        <div className="flex justify-around" style={{ width: "310px"}}>
          <div 
            className="flex flex-column" 
            onClick={() => {
              currentLinkEditor("StyleEditor")
              displayEditor("LinkTemplates", [367, 367, 0, 0], 0)
            }}
            style={{ width: "90px", height: "60px"}}
          >
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "25px", height: "25px"}} ios={createOutline} md={createOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Background</small>
            </div>
          </div>

          <div 
            className="flex flex-column" 
            onClick={() => {
              currentLinkEditor("StandardFontEditor")
              displayEditor("LinkTemplates", [367, 0, 367, 0], 0)
            }} 
            style={{ width: "90px", height: "60px"}}
          >
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "25px", height: "25px"}} ios={textOutline} md={textOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Font</small>
            </div>
          </div>
        </div>
      </div>

      <Footer 
        back={() => {
          displayEditor("LinkTemplates", [367, 0, 367, 0], 0)
          currentLinkEditor("Templates")
        }} 
        name="Link Editors" reset={false}
      />
    </div>
  )
}

export default EditorTypes
