import React from "react";
import { PropTypes } from "prop-types";
import { IonInfiniteScroll, IonInfiniteScrollContent} from '@ionic/react';
import { ClipLoader } from "react-spinners";
import TagCard from "../taggable_products/TagCard";
import ProductService from "../services/ProductService";
var mobile = require("is-mobile");

const List = (props) => {
  const {
    products,
    isLoading,
    hasMore,
    loadMore,
    media,
    authToken,
    selectProduct,
    removeMediaProduct,
  } = props;

  const searchNext = async (event) => {
    await loadMore();
    event.target.complete();
  }

  const hasProducts = products.length >= 1;
  const isMobile = mobile();

  const cols = isMobile ? [[], []] : [[], [], []];
  const indexCount = isMobile ? 2 : 3;
  const cssColumns = isMobile ? "c1in2" : "c1in3";

  products.map((media, index) => {
    cols[index % indexCount].push(media);
  });

  return (
    <div className="container-1010 max-w800px">
      { hasProducts && 
        <div>
          <div className="flex justify-between cell-list masonry pr1">
            {cols.map((col, index) => (
              <div className={`${cssColumns} flex flex-column`} key={index}>
                {col.map((product) => (
                  <TagCard
                    authToken={authToken}
                    media={media}
                    product={product}
                    selectProduct={selectProduct}
                    removeMediaProduct={removeMediaProduct}
                  />
                ))}
              </div>
            ))}
          </div>

          <IonInfiniteScroll 
            threshold="100px" 
            onIonInfinite={(e) => searchNext(e)}
          >
            <IonInfiniteScrollContent
              class="infinite-scroll-color"
              loadingText="Loading"
            >
            </IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </div>
      }

      {isLoading && (
        <div className="flex justify-center loader">
          <ClipLoader color={"#000000"} loading={true} />
        </div>
      )}
      
      {!hasProducts && !isLoading && (
        <div>
          <p className="flex justify-center bold">No products</p>
          <p className="flex justify-center px2">
            Create a product then tag them on your Instagram posts.
          </p>
        </div>
      )}
    </div>
  );
}

export default List;