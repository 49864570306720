import ProfileSettingService from '../../services/ProfileSetting';
import store from '../../store';

const Colors = (props) => {
  const dotCss = (colour) => {
    return {
      height: "44px",
      width: "44px",
      backgroundColor: colour,
      borderRadius: "50%",
      display: "inline-block",
      margin: "0.3rem",
      border: "white solid 1px"
    }
  }

  const colors =[
    "#000000", 
    "#898989", 
    "#ffffff", 
    "#FAE477",
    "#F7D033",
    "#F4BC2F",
    "#8AD96D",
    "#5EB941",
    "#339C35",
    "#4E9691",
    "#4D7B67",
    "#F8C7E1",
    "#F6BBB9",
    "#F28694",
    "#EA6267",
    "#ED3F41",
    "#C52917",
    "#E5B786",
    "#EAA14F",
    "#B34418",
    "#A5E2DA",
    "#76E1F5",
    "#45B5E5",
    "#3177C7",
    "#619AE9",
    "#4388F7",
    "#2656A4",
    "#1F2CAB",
  ]

  return(
    <div className="center pt2 profile-editor-tabs">
      {
        colors.map(color => 
          <div style={dotCss(color)} onClick={() => props.updateService(color)}></div>
        )
      }
    </div>
  )
}

export default Colors
