import ProfileSettingService from '../../../services/ProfileSetting';
import store from '../../../store';
import produce from "immer"
import displayEditor from '../../shared/DisplayEditor';
import currentLinkEditor from '../shared/CurrentLinkEditor';

const Base = (props) => {
  const profileSettingService = new ProfileSettingService();
  const authToken = store.getState().authTokenState
  const styles = store.getState().styles
  const params = props.params;

  const updateButtonStyle = async () => {
    updateStore()
  }

  const updateStore = async () => {
    const styles = store.getState().profileSettingChangesState
    const nextState = produce(styles, draftState => {
      draftState['button_css'] = params
    })
  
    store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
  }
  
  const button = <div className="relative button mb2 words-in-div w100" style={ params } />

  const customizeOnClick = () => {
    displayEditor("LinkTemplates", [140, 140, 0, 0], 0)
    currentLinkEditor("EditorTypes")
  }

  return(
    <div className="card mr4">
      <div className="bg-white rounded p3" style={{ height: "200px" }} onClick={(e) => updateButtonStyle()}>
        <div className="pt6">
          { button }
          { button }
          { button }
        </div>
      </div>

      <p 
        className="center-align pb2 text-blue" 
        onClick={() => {
          updateButtonStyle()
          customizeOnClick()
        }}
      >
        customize
      </p>
    </div>
  )
}

export default Base
