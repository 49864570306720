import Image from '../../shared/Image';
import { updateStore } from './UpdateImage';
import Footer from '../../Footer';
import displayEditor from '../../shared/DisplayEditor';

const Editor = (props) => {
  return(
    <div className="center">
      <div className="center pt1" style={{ height: "117px", maxWidth: "350px" }}>
        <div className="pb2">
          <Image storeAttribute="background_image_url" updateStore={updateStore}/> 
        </div>
      </div>

      <Footer back={() => displayEditor("ProfileEditor", [140, 0.5, 140, 0], 0)} name="Avatar" reset={false}/>
    </div>
  )
}

export default Editor
