import React from 'react';
import PropTypes from 'prop-types';
import UserProducts from "./UserProducts";
import ProductService from "../services/ProductService";
import store from "../store";

export default class UserProductsWrapper extends React.Component {
  static propTypes = {
    authToken: PropTypes.string
  };

  constructor() {
    super();

    this.state = {
      styles: store.getState().styles
    };

    this.productService = new ProductService();
  }

  render() {
    const { profileUser, authToken, styles } = this.props;

    return (
      <div className="container-1010 max-w800px">
        <UserProducts
          profileUser={profileUser}
          authToken={authToken}
          styles={styles}
        />
      </div>
    );
  }
}
