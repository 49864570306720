import React, { Component } from "react";
import LinksService from "../services/LinksService";
import { ClipLoader } from "react-spinners";

export default class New extends React.Component {
  constructor() {
    super();

    this.state = {
      loading: false,
      showTitleAlert: false,
      showUrlAlert: false,
    };

    this.linksService = new LinksService();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handlUrlChange = (event) => {
    const target = event.target;
    const value = target.value.toLowerCase();
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSave = async () => {
    const { name, url } = this.state
    const { authToken, toggleNewForm, addLinkToList } = this.props;

    this.setState({showTitleAlert: false})
    this.setState({showUrlAlert: false})
    const params = {
      name: name, 
      url: url
    }

    if (name, url) {
      this.handleLoading(true)
      const res = await this.linksService.create(params, authToken)
      this.handleLoading(false)
  
      addLinkToList(res)
      toggleNewForm()
    } else {
      console.log("!name", !name)
      if (!name) {
        this.setState({showTitleAlert: true})
      }
      if (!url) {
        this.setState({showUrlAlert: true})
      }
    }
  }

  handleLoading = (boolean) => {
    this.setState({ loading:  boolean});
  }
  
  render() {
    const { loading, showTitleAlert, showUrlAlert, url } = this.state;
    const { toggleNewForm } = this.props;

    return (
      <div className="px4">
        <h2 className="mb0 bold font-brand">Create Link</h2>

        <div className="absolute" style={{ right: "0px", top: "0px" }} >
          <i 
            className="fa fa-lg fa-times p2 mr2" 
            onClick={() => toggleNewForm()}
          />
        </div>

        {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        }
        {
          !loading && <div>
            <div className="flex items-baseline">
              <p className="mb0 pt3">Title</p>
              { showTitleAlert && <h6 className="pl2 text-red">Fill in title</h6> } 
            </div> 
            <input
              type="text"
              style={{ width: "100%" }}
              name="name"
              autoFocus
              onChange={this.handleInputChange}
            />
            <div className="flex items-baseline">
              <p className="mb0 pt2">Url</p>
              { showUrlAlert && <h6 className="pl2 text-red">Fill in url eg www.asos.com</h6> }
            </div> 
            <input
              type="text"
              style={{ width: "100%" }}
              name="url"
              value={url}
              onChange={this.handlUrlChange}
            />
            
            <button
              className="button button-black w100 mt4"
              onClick={this.handleSave}
            >
              Save
            </button>
          </div>
        }
      </div>
    )
  }
}
