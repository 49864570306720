import store from '../../../store';
import produce from "immer"

export const updateFont = async (fontParams) => {
  const styles = store.getState().profileSettingChangesState
  const usernameCss = { ...styles.username_css, ...fontParams  }

  const params = {}
  params['username_css'] = usernameCss

  updateStore(usernameCss)
}

const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const nextState = produce(styles, draftState => {
    draftState['username_css'] = value
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}
