import store from '../../../store';
import produce from "immer"

export const updateColor = async (color) => {
  updateStore({ backgroundColor: color })
}

const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const nextState = produce(styles, draftState => {
    draftState['background_image_url'] = null
    draftState['background_base_64'] = null
    draftState['background_css'] = value
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}
