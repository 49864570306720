import Colors from '../../shared/Colors';
import ColorPicker from '../../shared/ColorPicker';
import Font from '../../shared/Font';
import { useState } from 'react';
import store from '../../../store';
import { updateColor } from './UpdateColor';
import { updateFont } from './UpdateFont';
import Footer from '../../Footer';
import displayEditor from '../../shared/DisplayEditor';

const Editor = (props) => {
  const [currentTab, setCurrentTab] = useState("fontTab")
  const styles = store.getState().styles

  const underLineTab = (tab) => {
    if (currentTab === tab) {
      return { borderBottomWidth: "1px", borderBottomStyle: "solid" } 
    } else {
      return {}
    } 
  }
  
  return(
    <div className="center">
      <div className="flex justify-center border-bottom-solid border-grey">
        <div className="flex justify-around w100">
          <div className="w33" style={underLineTab("fontTab")} onClick={() => setCurrentTab("fontTab")}>
            <small className="text-grey-xdark flex justify-center">Font</small>
          </div>
          <div className="w33" style={underLineTab("colorTab")} onClick={() => setCurrentTab("colorTab")}>
            <small className="text-grey-xdark flex justify-center">Colors</small>
          </div>
          <div className="w33" style={underLineTab("colorPickerTab")} onClick={() => setCurrentTab("colorPickerTab")}>
            <small className="text-grey-xdark flex justify-center">Picker</small>
          </div>
        </div>
      </div>

      { currentTab === "fontTab" && <Font updateService={updateFont}/> }
      { currentTab === "colorTab" && <Colors updateService={updateColor}/> }
      { currentTab === "colorPickerTab" && <ColorPicker updateService={updateColor} defaultColor={styles.username_css?.color} /> }

      <Footer back={() => displayEditor("ProfileEditor", [140, 0.5, 140, 0], 0)} name="Header" reset={false}/>
    </div>
  )
}

export default Editor
