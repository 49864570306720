import { IonIcon } from '@ionic/react';
import { imageOutline, textOutline, personCircleOutline } from 'ionicons/icons';
import store from '../../store';
import Footer from '../Footer';
import produce from "immer"
import displayEditor from '../shared/DisplayEditor';

const List = (props) => {
  const resetProfile = async () => {
    const styles = store.getState().profileSettingChangesState

    const nextState = produce(styles, draftState => {
      draftState["background_image_url"] = null
      draftState["header_image_url"] = null
      draftState["delete_header_image"] = true
      draftState["delete_background_image"] = true
      draftState["background_css"] = {}
      draftState["header_background_css"] = null
      draftState["username_css"] = {}
      draftState["tabs_css"] = {}
      draftState["button_css"] = { backgroundColor: "black" }
    })

    store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
  }

  return(
    <div className="center">
      <div className="flex justify-center">
        <div className="flex justify-around" style={{ maxWidth: "600px", width: "100%"}}>
          <div className="flex flex-column mx3" style={{ height: "60px", width: "20%"}} onClick={() => displayEditor("BackgroundEditor", [367, 367, 0, 0], 0)}>
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "30px", height: "30px"}} ios={imageOutline} md={imageOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Background</small>
            </div>
          </div>

          <div className="flex flex-column mx3" style={{ height: "60px", width: "20%"}} onClick={() => displayEditor("HeaderEditor", [367, 367, 0, 0], 0)}>
            <div className="flex justify-center">
              <img className="" style={{color: "grey", width: "30px", height: "30px"}} src="assets/icon/header.svg"></img>
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Header</small>
            </div>
          </div>

          <div className="flex flex-column mx3" style={{ height: "60px", width: "20%"}} onClick={() => displayEditor("AvatarEditor", [197, 0.5, 100, 0], 0)}>
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "30px", height: "30px"}} ios={personCircleOutline} md={personCircleOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Avatar</small>
            </div>
          </div>

          <div className="flex flex-column mx3" style={{ height: "60px", width: "20%"}} onClick={() => displayEditor("TabsEditor", [367, 367, 0, 0], 0)}>
            <div className="flex justify-center">
              <img className="" style={{color: "grey", width: "30px", height: "30px"}} src="assets/icon/links.svg"></img>
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Tabs</small>
            </div>
          </div>

          <div className="flex flex-column mx3" style={{ height: "60px", width: "20%"}} onClick={() => displayEditor("UsernameEditor", [367, 367, 0, 0], 0)}>
            <div className="flex justify-center">
              <IonIcon style={{ color: "grey", width: "30px", height: "30px"}} ios={textOutline} md={textOutline} />
            </div>
            <div className="flex justify-center">
              <small className="text-grey-xdark">Username</small>
            </div>
          </div>
        </div>
      </div>

      <Footer back={() => displayEditor("EditorList", [153, 0.5, 153, 0], 0)} name="Profile Editor" reset={resetProfile}/>
    </div>
  )
}

export default List
