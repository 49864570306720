import React, { Component } from "react";
import LinksService from "../services/LinksService"
import ClickEventService from "../services/ClickEventService";
import New from "./New";
import Link from "./Link";
import { ClipLoader } from "react-spinners";
import store from "../store";
import { IonRefresher, IonRefresherContent, IonFab, IonFabButton, IonContent, IonIcon } from '@ionic/react';
import Sheet from 'react-modal-sheet';
import { addOutline } from 'ionicons/icons';

export default class LinkList extends React.Component {
  constructor(props) {
    super(props);
    const isOpen = store.getState().profileEditorState.isOpen

    this.state =  {
      links: [],
      loading: true,
      showNewForm: false,
      styles: isOpen ? store.getState().profileSettingChangesState : store.getState().styles
    }

    this.linksService = new LinksService()
    this.clickEventService = new ClickEventService();
    this.loadLinks()

    store.subscribe(() => {
      this.setState({ styles: isOpen ? store.getState().profileSettingChangesState : store.getState().styles })
    })
  }
  

  async loadLinks() {
    const user = store.getState().userState
    const res = await this.linksService.index(user.uuid)

    this.setState({links: res, loading: false})
  }

  toggleNewForm = () => {
    const { showNewForm }  = this.state;

    this.setState({ showNewForm: showNewForm === false ? true : false })
  }

  handleLoading = (boolean) => {
    this.setState({ loading: boolean })
  }

  addLinkToList = (link) => {
    const links = this.state.links

    this.setState({ links: links.concat(link)})
  }

  updateLinkList = (removeLink) => {
    const { links } = this.state;

    const updatedLink = links.filter(l => l.uuid !== removeLink.uuid)
    this.setState({ links: updatedLink })
  }

  render() {
    const { links, showNewForm, loading, styles } = this.state;
    const authToken = store.getState().authTokenState
    const doRefresh = (event) => {
      this.loadLinks()
      setTimeout(() => {
        event.detail.complete();
      }, 500);
    }

    return (
      <div className="container-no-padding max-w800px relative px2">
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        {
          links.length === 0 && !loading && <div className="flex justify-center">
            <div className="bg-white rounded p2 border-full border-grey" >
              <p className="bold center-align">Create your first link</p>
              <div className="flex justify-center">
                <p>Press the red + button at the bottom right</p>
              </div>
            </div>
          </div>
        }

        {
          links.map((link, index) =>
            <Link 
              link={link} 
              authToken={authToken} 
              updateLinkList={this.updateLinkList} 
              handleLoading={this.handleLoading}
              index={index}
              styles={styles}
            />
          )
        }

        {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        }

        {
          showNewForm &&
          <Sheet 
            isOpen={showNewForm} 
            onClose={() => this.toggleNewForm()} 
            snapPoints={[-100, 400, 100, 0]}
          >
            <Sheet.Container  >
              <Sheet.Header />
              <Sheet.Content>
                <New authToken={authToken} toggleNewForm={this.toggleNewForm} addLinkToList={this.addLinkToList}/>
              </Sheet.Content>
            </Sheet.Container>
          </Sheet>
        }          

        {
          authToken && <div className="absolute z3 hover-bottom-right-buttom test-product-creator">
            <IonContent>
              <IonFab slot="fixed" horizontal="end" vertical="bottom" >
                <IonFabButton color="danger">
                  <IonIcon onClick={() => this.toggleNewForm()} size="large" color="light" ios={addOutline} md={addOutline} />
                </IonFabButton>
              </IonFab>
            </IonContent>
          </div>
        }
      </div>
    )
  }
}