import store from "../../store"
import produce from "immer"
import { isPlatform } from '@ionic/react';

const displayEditor = async (currentEditor, snapPoints, initialSnap) => {
  const profileEditorState = store.getState().profileEditorState
  
  const calculcatedSnapPoints = (snapPoints) => {
    if(isPlatform("ios")) {
      const height = snapPoints[0]
      snapPoints[0] = height + 13
      return snapPoints
    } else {
      return snapPoints
    }
  } 

  const nextState = produce(profileEditorState, draftState => {
    draftState['currentEditor'] = currentEditor
    draftState['snapPoints'] = calculcatedSnapPoints(snapPoints)
    draftState['initialSnap'] = initialSnap
  })

  store.dispatch({ type: 'profileEditorState', payload: nextState })
}

export default displayEditor