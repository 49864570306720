import Base from './Base';

const Underline = (props) => {
  return(
    <Base 
      params={
        { 
          borderBottom: "solid 1px black",
          borderRadius: "0px",
          color: "black",
          textAlign: "left",
          paddingLeft: "0px",
          paddingRight: "0.5rem",
          paddingTop: "0.4rem",
          paddingBottom: "1rem",
          type: "Underline"
        }
      }
    />
  )
}

export default Underline
