export default class SessionService {
  create(params) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/auth/sign_in`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }
}