export default class ProductService {
  index(page, authToken, user) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${user.uuid}/products?page=${page}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }

  create(authToken, userUuid, params) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${userUuid}/products`, {
      method: "POST",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }

  update(authToken, userUuid, prductUuid, params) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${userUuid}/products/${prductUuid}`, {
      method: "PATCH",
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }

  destroy(uuid, authToken, user) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/users/${user.uuid}/products/${uuid}`, {
      method: "DELETE",
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken,
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
