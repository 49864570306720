export default class TaddarVisionService {
  similarProducts(authToken, imageUrl) {
    const params = {
      image_url: imageUrl
    };

    return fetch("/api/v1/similar_products", {
      body: JSON.stringify(params),
      headers: new Headers({
        "Content-Type": "application/json",
        "X-AUTH-TOKEN": authToken
      }),
      method: "POST"
    }).then(res => {
      return res.json();
    });
  }
}
