import { Redirect, Route } from 'react-router-dom';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { personOutline, analyticsOutline } from 'ionicons/icons';
import Tab1 from '../pages/Tab1';
import Tab2 from '../pages/Tab2';
import Tab3 from '../pages/Tab3';
import ShopMedia from '../pages/ShopMedia';
import TagProducts from '../pages/TagProducts';
import Products from '../pages/profile/Products';
import ShoppingFeed from '../pages/profile/ShoppingFeed';
import ConnectInstagram from '../components/external_identities/ConnectInstagram';
import Menu from '../components/shared/Menu';
import { Provider } from 'react-redux';
import { PersistGate } from "redux-persist/lib/integration/react";
import store from '../components/store';
import { persistStore } from 'redux-persist';
import { ClipLoader } from "react-spinners";
import CreateForm from '../components/products/CreateForm';
import EditForm from '../components/products/EditForm';
import ProfileEditor from '../pages/profile/Editor';
import Analytics from '../components/Analytics';
import Account from '../pages/Account';
import { isPlatform } from '@ionic/react';

const AuthenticatedRoutes = () => {
  const user = store.getState().userState
  const persistor = persistStore(store)
  
  const loading = () => {
    return(
      <div className="center">
        <div className="bg-white p1">
          <ClipLoader color={"#000000"} loading={true}  />
        </div>
      </div>
    )
  }

  return(
    <Provider store={store}>
      <PersistGate loading={loading()} persistor={persistor}>
        <Menu/>
        <IonTabs>
          <IonRouterOutlet id="menuContent" animated={false}>
            <Route exact path="/">
              <Redirect to={`/${user.slug}`} />
            </Route>
            <Route exact path={`/${user.slug}`}>
              <Tab2 />
            </Route>
            <Route exact path={`/${user.slug}/products`}>
              <Products />
            </Route>
            <Route exact path={`/profile_editor`}>
              <ProfileEditor />
            </Route>
            <Route exact path={`/account`}>
              <Account />
            </Route>
            <Route exact path={`/${user.slug}/products/new`}>
              <CreateForm />
            </Route>
            <Route path={`/${user.slug}/products/:uuid/edit`} render={(props) => <EditForm {...props.match.params} />} />
            <Route exact path={`/connect_instagram`}>
              <ConnectInstagram />
            </Route>
            <Route exact path={`/${user.slug}/images`}>
              <ShoppingFeed />
            </Route>
            <Route exact path="/tab1">
              <Analytics />
            </Route>
            <Route path="/tab3">
              <Tab3 />
            </Route>
            <Route path="/medias/:id" render={(props) => <ShopMedia {...props.match.params} />} />
            <Route path="/tag_products/:id" render={(props) => <TagProducts {...props.match.params} />} />
          </IonRouterOutlet>
          
          <IonTabBar slot="bottom" className="py2" style={{ marginBottom: isPlatform("ios") ? "13px" : "0px" }}>
            <IonTabButton tab="tab1" href="/tab1">
              <IonIcon icon={analyticsOutline} />
              <IonLabel>Analytics</IonLabel>
            </IonTabButton>
            <IonTabButton tab="tab2" href={`/${user.slug}`}>
              <IonIcon icon={personOutline} />
              <IonLabel>Profile</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </PersistGate>
    </Provider>
  )
}

export default AuthenticatedRoutes 