import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import store from '../components/store';
import { useState } from 'react';
import { ClipLoader } from 'react-spinners';
import UserService from '../components/services/UserService';

const Account = () => {
  const authToken = store.getState().authTokenState
  const [user, setUser] = useState(store.getState().userState)
  const [userName, setUsername] = useState(user.username)
  const [email, setEmail] = useState(user.email)
  const [loading, setLoading] = useState(false)
  const userService = new UserService()

  const updateUsername = (e) => {
    const newUserName = e.target.value
    setUsername(newUserName)
  }

  const updateEmail = (e) => {
    const email = e.target.value
    setEmail(email)
  }

  const save = async () => {
    setLoading(true)

    const request = await userService.update({ user: { username: userName, email: email } })

    setUser(request)
    store.dispatch({ type: 'userState', payload: request })
    setLoading(false)
  }

  return(
    <IonPage className="p4">
      <IonContent fullscreen>
        <div>
          <h1 className="bold font-aleo">Account</h1>
        </div>

        <div className="pb2">
          <p className="mb0 ">User name</p>
          <input
            type="text"
            name="username"
            style={{ width: "100%" }}
            value={userName}
            onChange={(e) => updateUsername(e)}
          />
        </div>

        <div className="pb2">
          <p className="mb0 ">Email</p>
          <input
            type="text"
            name="email"
            style={{ width: "100%" }}
            value={email}
            onChange={(e) => updateEmail(e)}
          />
        </div>

        <button
          className="button button-black button-large w100 mt2"
          onClick={() => save()}
        >
          {loading && <ClipLoader color={"#ffffff"} loading={true} />}
          {!loading && <div className="my1">Save</div>}
        </button>
      </IonContent>
    </IonPage>
  )
}

export default Account;
