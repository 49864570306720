import React from 'react';
import './Tab2.css';
import ProfileLinks from './profile/Links';

const Tab2: React.FC = () => {
  return (
    <ProfileLinks />
  );
};

export default Tab2;
