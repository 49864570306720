import InstagramService from "../services/external_identities/InstagramService";
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser';
import { useIonRouter } from '@ionic/react';
import { Browser } from '@capacitor/browser';
import store from "../store";
import { useState, useEffect } from 'react';
import { IonRefresher, IonRefresherContent, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/react';
import { ClipLoader } from "react-spinners";
import InstagramPostService from "../services/users/InstagramPostService";
import Media from "./Media";
var mobile = require("is-mobile");


const Medias = (props) => {
  const instagramService = new InstagramService()
  const authToken = store.getState().authTokenState
  const instagram_login_url = process.env.REACT_APP_INSTAGRAM_CALLBACK_URL
  const isProfileUser = true
  const [loading, setLoading] = useState(false);
  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState(false);
  const [displayConnectInstagram, setDisplayConnectInstagram] = useState(false);
  const [medias, setMedias] = useState([]);
  const [mediaIds, setMediaIds] = useState([]);
  const [nextMaxId, setNextMaxId] = useState(null);
  const instagramPostService = new InstagramPostService()
  const router = useIonRouter();
  const [user, setUser] = useState(store.getState().userState);
  useEffect(() => loadMedias(), [])

  store.subscribe(() => {
    setUser(store.getState().userState)
  })

  const loadMedias = async () => {
    if (user.instagram_token_expiry) {
      setLoading(true);

      const response = await instagramPostService.index(
        nextMaxId,
        authToken,
        user
      );

      if (response.error === undefined) {
        const result = medias.concat(
          response.medias.filter((media) => mediaIds.indexOf(media.media_id) < 0)
        )

        store.dispatch({ type: 'mediasState', payload: result })

        setMedias(result)
        setNextMaxId(response.next_max_id)
        setLoading(false)
        setDisableInfiniteScroll(false)
      } else {
        setDisplayConnectInstagram(true)
        setLoading(false)
        setDisableInfiniteScroll(true)
      }
    }
  };

  const isMobile = mobile();
  const cols = isMobile ? [[], []] : [[], [], []];
  const indexCount = isMobile ? 2 : 3;
  const cssColumns = isMobile ? "c1in2" : "c1in3";

  medias.map((media, index) => {
    cols[index % indexCount].push(media);
  });

  async function searchNext(event) {
    await loadMedias();
    event.target.complete();
  }

  const showInstagramLogin = async () => {
    const inAppBrowserRef = await InAppBrowser.create(instagram_login_url, '_blank', 'hidden=yes')

    inAppBrowserRef.on('loadstop').subscribe(event => { 
      if(event.url.split("instagram_token=")[1] !== undefined){
        connectInstagram(event.url.split("instagram_token=")[1])
        inAppBrowserRef.close()
      }
    });
    
    inAppBrowserRef.show()
  };

  const connectInstagram = async (instagram_access_token) => {
    setLoading(true)
    const res = await instagramService.update(authToken, instagram_access_token)
    store.dispatch({ type: 'userState', payload: res })
    router.push(`/${res.slug}/images`);
    window.location.reload()
    setLoading(false)
  }

  const doRefresh = (event) => {
    loadMedias()
    setTimeout(() => {
      event.detail.complete();
    }, 500);
  }

  return (
    <div className="container-1010 max-w800px">
      <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
        <IonRefresherContent></IonRefresherContent>
      </IonRefresher>

      {
        !user.instagram_token_expiry && isProfileUser && medias.length == 0 && !loading && <div className="flex justify-center">
          <div className="bg-white rounded p2 border-full border-grey" >
            <div onClick={() => showInstagramLogin()} >
              <p>Create a shoppable Instagram feed</p>
              <div className="flex justify-center">
                <button className="button button-black">Connect Instagram</button>
              </div>
            </div>
          </div>
        </div>
      }

      {
        medias.length == 0 && displayConnectInstagram && user.instagram_token_expiry && isProfileUser && <div className="flex justify-center">
          <div className="bg-white rounded p2 border-full border-grey" >
            <a href={instagram_login_url} >
              <p>There was an error please reconnect Instagram</p>
              <div className="flex justify-center">
                <button className="button button-black">Connect Instagram</button>
              </div>
            </a>
          </div>
        </div>
      }

      {
        medias.length == 0 && !loading && user.instagram_token_expiry && <div className="flex justify-center">
          <div className="bg-white rounded p2 border-full border-grey" >
            <p className="bold center-align">No Images</p>
            <div className="flex flex-column justify-center">
              <p>Unable to get images from Instagram. Try, connecting again, log out then login, or contact support.</p>
              <button className="button button-black" onClick={() => showInstagramLogin()}>Connect Instagram</button>
            </div>
          </div>
        </div>
      }

      <div>
        { medias.length > 0 &&
          <div className="flex justify-between cell-list masonry pr1">
            {cols.map((col, index) => (
              <div className={`${cssColumns} flex flex-column`} key={index}>
                {col.map((media) => (
                  <Media
                    key={media.media_id}
                    media={media}
                    user={user}
                    authToken={authToken}
                    user_media_uuid={user.uuid}
                    isProfileUser={isProfileUser}
                  />
                ))}
              </div>
            ))}
          </div>
        }

        {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading} />
            </div>
          </div>
        }
        <IonInfiniteScroll
          threshold="100px"
          disabled={disableInfiniteScroll}
          onIonInfinite={(e) => searchNext(e)}
        >
          <IonInfiniteScrollContent
            class="infinite-scroll-color"
            loadingSpinner={null}
          >
          </IonInfiniteScrollContent>
        </IonInfiniteScroll>
      </div>
    </div>
  );
}

export default Medias;