import React, { Component } from "react";
import LinksService from "../services/LinksService";
import { ClipLoader } from "react-spinners";

export default class Edit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.link.name,
      url: props.link.url,
      loading: false
    };

    this.linksService = new LinksService();
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  }

  handleSave = async () => {
    const { name, url } = this.state
    const { authToken, toggleEditForm, link, updateLink, handleLoading } = this.props;

    
    const params = {
      link: {
        name: name, 
        url: url,
        uuid: link.uuid
      }
    }

    this.setState({ loading: true })
    const res = await this.linksService.update(params, authToken)
    updateLink(res)
    this.setState({ loading: false })
    toggleEditForm()
  }

  
  render() {
    const { toggleEditForm } = this.props;
    const { name, url, loading } = this.state;

    return (
      <div className="px4 max-w600px">
        <h2 className="mb0 bold font-brand">Edit Link</h2>
        <div className="absolute" style={{ right: "0px", top: "0px" }} >
          <i 
            className="fa fa-lg fa-times p2 mr2" 
            onClick={() => toggleEditForm()}
          />
        </div>

        {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        }

        <p className="mb0 pt3">Title</p>
        <input
          type="text"
          style={{ width: "100%" }}
          name="name"
          value={name}
          onChange={this.handleInputChange}
        />
        <p className="mb0 mt2">Url</p>
        <input
          type="text"
          style={{ width: "100%" }}
          name="url"
          value={url}
          onChange={this.handleInputChange}
        />
        <button
          className="button button-black w100 mt4"
          onClick={this.handleSave}
        >
          Save
        </button>
      </div>
    )
  }
}
