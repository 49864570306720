import { useState, useEffect } from 'react';
import { useIonRouter, IonPage, IonButton } from '@ionic/react';
import { } from "../../images/instagram.svg";
import SessionService from '../../components/services/SessionService';
import store from "../../components/store";
import { BrowserRouter as Router, Link } from "react-router-dom"
import { ClipLoader } from "react-spinners";

const Login = () => {
  const sessionService = new SessionService()
  const router = useIonRouter();
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [errors, setErrors] = useState([])
  const [loading, setLoading] = useState(false)


  const fetchSessions = async () => {
    setLoading(true)
    const res  = await sessionService.create({ email: email, password: password })

    if(res.errors) {
      setLoading(false)
      setErrors(res.errors)
    } else {
      await store.dispatch({ type: 'stylesState', payload: res.styles })
      await store.dispatch({ type: 'userState', payload: res.user })
      await store.dispatch({ type: 'authTokenState', payload: res.auth_token })
      setLoading(false)
      router.push(`/${res.user.slug}`);
    }
  }

  return (
    <IonPage className="bg-white">
      <div>
        <div className="flex-auto pt6">
          <div className="flex justify-center pt6">
            <img className="max-w250px" src="assets/images/logo_black.png" />
          </div>
          <div className="flex justify-center">
            <div className="form-half p2 max-w500px">
              <div className="bg-white rounded-large">
                <div className="flex flex-column p4">
                  <h1 className="font-brand text-brand-blue-dark">Login</h1>
                  <form novalidate="novalidate" class="simple_form new_user">
                    <div className="form-inputs">

                      <div className="mb2">
                        <label class="control-label">Email</label>
                        <input class="form-control password optional" type="email" onChange={(e) => setEmail(e.target.value)} />
                      </div>

                      <div className="mb2">
                        <label class="control-label">Password</label>
                        <input class="form-control password optional" type="password" onChange={(e) => setPassword(e.target.value)} />
                      </div>
                    </div>
                  </form>

                  {
                    loading && <button className="button button-brand-brown w100 mt2">
                      <ClipLoader color={"#ffffff"} loading={loading} />
                    </button>
                    
                  }
                  
                  {
                    !loading && <button className="button button-brand-brown w100 mt2" onClick={(e) => fetchSessions()}>Log in</button>
                  }

                  {
                    errors.length > 0 && <div className="flex items-baseline border-full border-solid rounded mt3 border-red">
                      <p className="text-red pt2 px2">{errors}</p>
                    </div>
                  }

                  <div className="pt3">
                    <Link to={`/signup`}>
                      <p className="text-blue">Sign up</p>
                    </Link>

                    <Link to={`/resend_confirm_email`}>
                      <p className="text-blue">Resend Confirm email?</p>
                    </Link>

                    <Link to={`/reset_password`}>
                      <p className="text-blue">Forgot password?</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Login;
