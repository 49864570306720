import { IonContent, IonPage } from '@ionic/react';
import LinkList from '../../../components/links/LinkList';
import ProfileWrapper from '../../../components/users/ProfileWrapper';
import UserService from '../../../components/services/UserService';
import { useEffect, useState } from 'react';
import store from '../../../components/store';
import ClickEventService from '../../../components/services/ClickEventService';
import { ClipLoader } from "react-spinners";

const Links = (props) => {
  const userService = new UserService()
  const user = store.getState().userState
  const authToken = store.getState().authTokenState
  const [slug, setSlug] = useState(props.match?.params?.slug)
  const [loading, setLoading] = useState(true)
  const [runClickEvent, setRunClickEvent] = useState(true)
  const clickEventService = new ClickEventService();
  
  useEffect(() => {
    fetchUser(slug)
  })

  const fetchUser = async () => {
    
    if (user === null) {
      const res = await userService.show(slug)
      store.dispatch({ type: 'userState', payload: res.user }) 
      store.dispatch({ type: 'stylesState', payload: res.styles })
      if (runClickEvent) { createClickEvent() }
    }

    setLoading(false)
  }

  const createClickEvent = () => {
    setRunClickEvent(false)

    if (authToken === null) {
      const params = {
        user_uuid: user.uuid,
      };
  
      clickEventService.create(params);
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen>
        {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        }

        {
          !loading && <ProfileWrapper styles={ store.getState().styles } body={<LinkList styles={ store.getState().styles }/>}/>
        }
      </IonContent>
    </IonPage>
  );
};

export default Links;
