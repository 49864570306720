import store from '../../../store';
import produce from "immer"

export const updateFont = async (fontParams) => {
  const styles = store.getState().profileSettingChangesState
  const buttonCss = { ...styles.button_css, ...fontParams  }

  const params = {}
  params['button_css'] = buttonCss

  updateStore(buttonCss)
}

const updateStore = async (value) => {
  const styles = store.getState().profileSettingChangesState
  const nextState = produce(styles, draftState => {
    draftState['button_css'] = value
  })

  store.dispatch({ type: 'profileSettingChangesState', payload: nextState })
}
