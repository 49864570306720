import React from "react";
import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import MeidaProductService from "../components/services/MediaProductService";
import List from "../components/taggable_products/List";
import InstagraProductService from "../components/services/InstagramPostProductsService";
import store from "../components/store";
import produce from "immer"
import { useState, useEffect } from 'react';

const TagProducts = (props) => {
  const mediaStore = store.getState().mediasState.find(obj => obj.id == props.id)
  const user = store.getState().userState
  const authToken = store.getState().authTokenState
  const router = useIonRouter();

  const [media, setMedia] = useState(mediaStore);
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [product_uuid, setProductUuid] = useState(null);
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);

  const instagramPostProductService = new InstagraProductService()
  const meidaProductService = new MeidaProductService()

  useEffect(() => loadProducts(), [])

  const loadProducts = async (restart) => {
    const response = await meidaProductService.index(
      page,
      media.public_id,
      true,
      user.uuid
    );

    const list = response.data.length === 0 ? [] : response.data;

    setProducts(products.concat(list))
    setHasMore(response.has_more)
    setIsLoading(false)
  };

  const loadMore = () => {
    if ((products.length > 0) & hasMore) {
      setPage(page + 1)
      setIsLoading(true)
      loadProducts()
    }
  };

  const tagProduct = async (top, left) =>  {
    const params = {
      instagram_post_product: {
        product_uuid: product_uuid,
        media_id: media.media_id,
        short_code: media.public_id,
        top: top,
        left: left,
      },
    };

    const response = await instagramPostProductService.create(
      params,
      authToken
    );

    setProductUuid(null)
    const newProducts =  products.filter(product => product.uuid !== product_uuid)
    setProducts(newProducts)
    const product = response.product;
    product.instagram_post_product_uuid = response.uuid;
    product.top = top;
    product.left = left;
    addMediaProduct(product);
    router.push(`/medias/${media.id}`);
  }

  function addMediaProduct(product) {
    const medias  = store.getState().mediasState
    const mediaId = props.id
    
    const nextState = produce(medias, (draftState) => {
      const media = draftState.find(obj => obj.id == mediaId)
      media.products = media.products.concat(product)
    })

    store.dispatch({ type: 'mediasState', payload: nextState })
  }

  const selectProduct = (product) => {
    setProductUuid(product.uuid)
  };

  const onMouseMove = (e) => {
    const myImg = document.querySelector("img.max-w100");
    const top = (e.nativeEvent.offsetY / myImg.clientHeight) * 100;
    const left = (e.nativeEvent.offsetX / myImg.clientWidth) * 100;

    setTop(top)
    setLeft(left)
  }

  const setProductLocation = () => {
    tagProduct(top, left);
  }

  const productSelected = product_uuid != null;

  const title = product_uuid === null ? "Select Product" : "Select Product Location"
  return (
    <IonPage>
      <IonHeader className="ion-no-border"></IonHeader><IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="dark" style={{padding: "0px"}} defaultHref="/"></IonBackButton>
          </IonButtons>
          <IonTitle>{title}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        { productSelected && (
          <div className="flex justify-center bg-grey-xxlight">
            <div className="max-w600px">
              <img
                onMouseMove={(e) => onMouseMove(e)}
                onClick={() => setProductLocation()}
                src={media.img_path}
                className="max-w100"
              />
            </div>
          </div>
        )}
        {!productSelected && (
          <div className="pt2">
            <List
              products={products}
              media={media}
              authToken={authToken}
              isLoading={isLoading}
              hasMore={hasMore}
              loadMore={loadMore}
              loadProducts={loadProducts}
              selectProduct={selectProduct}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}

export default TagProducts