import Base from './Base';

const Link = (props) => {
  return(
    <Base 
      params={{ backgroundColor: "black" }}
    />
  )
}

export default Link
