import { useState, useEffect } from 'react';
import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel } from '@ionic/react';
import { } from "../../images/instagram.svg";
import ResetPasswordService from '../../components/services/ResetPasswordService';
import store from "../../components/store";
import { BrowserRouter as Router, Link } from "react-router-dom"

const UpdatePassword = () => {
  const resetPasswordService = new ResetPasswordService()
  const router = useIonRouter();
  const [errors, setErrors] = useState([])
  const [token, setToken] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirmation, setPasswordConfirmation] = useState("")


  const handleChangePassowrd = async () => {
    const res  = await resetPasswordService.update(
      password, 
      passwordConfirmation,
      token
    )

    if(res.errors) {
      setErrors(res.errors)
    } else {
      await store.dispatch({ type: 'stylesState', payload: res.styles })
      await store.dispatch({ type: 'userState', payload: res.user })
      await store.dispatch({ type: 'authTokenState', payload: res.auth_token })
      router.push(`/${res.user.slug}`);
    }
  }

  return (
    <IonPage>
      <div>
        <div className="flex-auto">
          <div className="flex justify-center pt3">
            <Link to={`/login`}>
              <img className="max-w250px" src="assets/images/logo_black.png" />
            </Link>
          </div>
          <div className="flex justify-center">
            <div className="form-half p2 max-w500px">
              <div className="bg-white rounded-large">
                <div className="flex flex-column p4">
                  <h1 className="font-brand text-brand-brown">Change your password</h1>
                  <form novalidate="novalidate" class="simple_form new_user">
                    <div className="form-inputs">
                      <div className="mb2">
                        <label class="control-label">Check your email reset password token</label>
                        <input class="form-control password optional" type="password" onChange={(e) => setToken(e.target.value)} />
                      </div>
                      <div className="mb2">
                        <label class="control-label">Password</label>
                        <input class="form-control password optional" type="password" onChange={(e) => setPassword(e.target.value)} />
                      </div>
                      <div className="mb2">
                        <label class="control-label">Password confirmation</label>
                        <input class="form-control password optional" type="password" onChange={(e) => setPasswordConfirmation(e.target.value)} />
                      </div>
                    </div>
                  </form>
                  <button className="button button-brand-brown w100 mt2" onClick={(e) => handleChangePassowrd()}>Change password</button>
                  
                  {
                    errors.length > 0 && <div className="flex items-baseline border-full border-solid rounded mt3 border-red">
                      <p className="text-red pt2 px2">{errors}</p>
                    </div>
                  }

                  <div className="pt3 flex">
                    <Link to={`/login`}>
                      <p className="text-blue">Already have an account?</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default UpdatePassword;
