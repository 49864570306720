import React from "react";
import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonButtons, IonBackButton } from '@ionic/react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import store from "../components/store";
import produce from "immer"
import ReactCrop from "react-image-crop";
import SimilarProducts from "../components/products/SimilarProducts";
import TaggableProducts from "../components/taggable_products/TaggableProducts";
import Card from "../components/products/Card";
import TaddarVisionService from "../components/services/TaddarVisionService";
import { ClipLoader } from "react-spinners";
import InstagraProductService from "../components/services/InstagramPostProductsService";
import MeidaProductService from "../components/services/MediaProductService";
var mobile = require("is-mobile");

const ShopMedia = (props) => {
  const mediaStore = store.getState().mediasState.find(obj => obj.id == props.id)
  const user = store.getState().userState
  const authToken = store.getState().authTokenState

  const [media, setMedia] = useState(mediaStore);
  const [modalIsOpen, setModalIsOpen] = useState(true);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasCropped, setHasCropped] = useState(false);
  const [isTagging, setIsTagging] = useState(false);
  const [isExploring, setIsExploring] = useState(false);
  const [taggedProducts, setTaggedProducts] = useState(media.products);
  const [isOpen, setIsOpen] = useState(true);
  const [title, setTitle] = useState("Select Product");
  const [crop, setCrop] = useState({ unit: "px",});

  const isMobile = mobile();
  const isProfileUser = authToken !== null 
  const taddarVisionService = new TaddarVisionService();
  const instagramPostProductService = new InstagraProductService();
  const meidaProductService = new MeidaProductService();
  const router = useIonRouter();

  store.subscribe(() =>
    resetMediaAndProduct()
  )
    
  function resetMediaAndProduct() {
    const media = store.getState().mediasState.find(obj => obj.id == props.id)
    if (media !== undefined) {
      setMedia(media)
      setTaggedProducts(media.products)
    }
  }
  
  const onChange = (crop) => {
    this.setState({ crop, hasCropped: true, isExploring: true });
  };

  const search = () => {
    const { media, authToken } = this.props;
    const { crop } = this.state;
    const formData = new FormData();
    this.setState({ isLoading: true });

    formData.append("cropped_product[crop_x]", crop.x);
    formData.append("cropped_product[crop_y]", crop.y);
    formData.append("cropped_product[crop_w]", crop.width);
    formData.append("cropped_product[crop_h]", crop.height);
    formData.append("cropped_product[instagram_media_id]", media.id);
    formData.append("cropped_product[image]", media.img_path);

    fetch(`/api/v1/cropped_products`, {
      method: "POST",
      body: formData,
      headers: {
        "X-auth-token": authToken,
      },
    }).then((response) => {
      response.json().then((json) => {
        this.findSimilarProducts(json.image_url);
      });
    });
  };

  const findSimilarProducts = async (croppedProductUrl) => {
    const similarProducts = await taddarVisionService.similarProducts(
      authToken,
      croppedProductUrl
    );

    setIsLoading(false)
    setSimilarProducts(similarProducts)
  };

  function tagProduct() {
    setIsTagging(true)
  };

  const resetModal = () => {
    setIsTagging(false)
    setHasCropped(false)
    setSimilarProducts([])
    setTitle("Select Product")
  };

  function updateTaggedProducts(product) {
    setTaggedProducts(taggedProducts.concat([product]))
  };

  function createProductPage() {
    window.location = `/${user.username}/products/new`;
  };

  function isSearching(image) {
    setIsExploring(false)
    setCrop(
      {
        height: 52.16213378137141,
        width: 40.58454968672336,
        x: 31.08126378242151,
        y: 25.800693058910007,
      }
    )
  };

  function isNotExploring() {
    setSimilarProducts([])
    setIsExploring(false)
    setIsLoading(false)
    setHasCropped(false)
    setCrop(
      {
        unit: "px",
      }
    )
  };

  const cols = isMobile ? [[], []] : [[], [], []];
  const indexCount = isMobile ? 2 : 3;
  const cssColumns = isMobile ? "c1in2" : "c1in3";

  taggedProducts.map((product, index) => {
    cols[index % indexCount].push(product);
  });

  const underLineProducts = isExploring ? "" : "underline-text-black bold";
  const underLineExplore = isExploring ? "underline-text-black bold" : "";

  const taggedProductsPage = () => {
		router.push(`/tag_products/${media.id}`);
	}
  
  const deleteTag = async (product) => {
    const authToken = store.getState().authTokenState
    const medias = store.getState().mediasState

   await instagramPostProductService.destroy(
     product.instagram_post_product_uuid, authToken
   );

   const productId = product.uuid
   const mediaId = media.id

   const nextState = produce(medias, (draftState) => {
     const media = draftState.find(obj => obj.id == mediaId)
     media.products = media.products.filter(p => p.uuid !== productId)
   })

   store.dispatch({ type: 'mediasState', payload: nextState })
 };

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton mode="md" color="dark" style={{ padding: "0px" }} defaultHref={`/${user.slug}`}/>
          </IonButtons>
          <IonTitle>Shop</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <div className="container-no-padding max-w500px">
          {!isTagging && (
            <div>
              <div>
                <div className="flex pb1">
                  <div className="relative">
                    {!isExploring &&
                      taggedProducts.map((taggedProduct, index) => (
                        <div
                          className={`absolute z2 dot-number-white`}
                          style={{
                            display: "inline-block",
                            top: `calc(${taggedProduct.top}% - 8px )`,
                            left: `calc(${taggedProduct.left}% - 8px)`,
                            paddingTop: '2px',
                            boxShadow: "rgb(149 149 149 / 65%) 0px 0px 1px 9px",
                          }}
                        >
                          {index + 1}
                        </div>
                      ))}

                    {!isExploring && (
                      <img className="ReactCrop__image max-w600px z1 image-fit pb1" src={media.img_path} />
                    )}

                    {isExploring && (
                      <ReactCrop
                        src={media.img_path}
                        crop={crop}
                        onChange={this.onChange}
                        className="ReactCrop__image max-w600px z3"
                      />
                    )}
                  </div>
                </div>

                {isExploring && similarProducts.length == 0 && (
                  <div className="flex flex-column justify-center justify-baseline px2">
                    <h2 className="bold mb0 center-align">Shop Similar</h2>
                    <div className="flex justify-center">
                      <p className="max-w250px center-align">
                        Adjust crop around one item you want to search, then
                        press Search.
                      </p>
                    </div>
                  </div>
                )}

                {isLoading && (
                  <div className="pt4 sweet-loading center-align">
                    <ClipLoader color={"#000000"} loading={true} />
                  </div>
                )}

                <div className="flex justify-between pb2">
                  <div className="flex pl2 relative" style={{ top: "4px" }}>
                    <p className="bold">Products</p>
                  </div>

                  {isProfileUser && !isExploring && (
                    <button
                      className="button button-small btn-purple h100 mr2"
                      onClick={() => taggedProductsPage()}
                    >
                      Tag product
                    </button>
                  )}

                  {isExploring && (
                    <button
                      onClick={() => search()}
                      className="button btn-purple button-small h100 mr2"
                    >
                      Search
                    </button>
                  )}
                </div>

                { taggedProducts.length == 0 &&
                  !isLoading &&
                  !hasCropped &&
                  !isExploring && (
                    <div className="sweet-loading center-align p2">
                      {isProfileUser && !isExploring && (
                        <div>
                          <p className="text-grey-xdark bold">
                            Add a tagged product
                          </p>
                          <p className="text-grey-xdark">
                            Press the purple Tag Product button to begin
                          </p>
                        </div>
                      )}
                      {!isProfileUser && !isExploring && (
                        <div>
                          <p className="text-grey-xdark bold">
                            No Tagged products
                          </p>
                          <p className="text-grey-xdark">
                            Press explore to view similar products
                          </p>
                        </div>
                      )}
                    </div>
                  )}

                {!hasCropped && !isExploring && (
                  <div className="flex flex-wrap justify-between cell-list masonry">
                    {cols.map((col, indexColumn) => (
                      <div
                        className={`${cssColumns} flex flex-column`}
                        key={indexColumn}
                      >
                        {col.map((product, index) => (
                          <Card
                            product={product}
                            media={media}
                            index={indexColumn + 1}
                            deleteTag={deleteTag}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                )}

                <SimilarProducts products={similarProducts} />
              </div>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}

export default ShopMedia