import { useState } from 'react';
import { HexColorPicker } from "react-colorful";

const ColorPicker = (props) => {
  const [currentColor, setCurrentColor] = useState(props.defaultColor || "black")

  return(
    <div className="center profile-editor-tabs">
      <div className="pt2">
        <input
          type="text"
          className="mb1"
          name="redirect_url"
          style={{ width: "100%" }}
          value={currentColor}
          onChange={(event) => {
            setCurrentColor(event.target.value)
            props.updateService(event.target.value)
          } }
        />

        <HexColorPicker 
          color={currentColor} 
          onChange={(color) => {
            setCurrentColor(color)
            props.updateService(color) 
          }}
        />
      </div>
    </div>
  )
}

export default ColorPicker
