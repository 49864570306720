import { BrowserRouter as Router, Link } from "react-router-dom"

const TermsAndConditions = () => {
  return (
    <div className="min-h100">
    <div className="flex justify-center pt3">
      <Link to={`/login`}>
        <img className="max-w250px" src="assets/images/logo_black.png" />
      </Link>
    </div>
    <div className="container-no-padding max-w800px relative px2">
    <div style={{ overflow: "scroll", height: "800px"}}>
      <h1>Terms and Conditions</h1>
      <p>We collect information in a few different ways:</p>
      <p>1. When you give it to us or give us permission to obtain it</p>
      <p>By accessing the ShopMyFeed website ("Site") or using the services offered by ShopMyFeed ("Services") you agree and acknowledge to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms or to our Privacy Policy, please do not access the Site or use the Services. ShopMyFeed reserves the right to change these Terms at any time. We recommend that you periodically check this Site for changes.</p>
      <h3>REGISTRATION AND ELIGIBILITY</h3>
      <p>You represent and warrant that if you are an individual, you are of legal age to form a binding contract, or that if you are registering on behalf of an entity, that you are authorized to enter into, and bind the entity to, these Terms of Use and register for the Site. The Site is not available to individuals who are younger than 13 years old. ShopMyFeed may, in its sole discretion, refuse to offer the Site to any person or entity and change its eligibility criteria at any time. You are solely responsible for ensuring that these Terms of Use are in compliance with all laws, rules and regulations applicable to you and the right to access the Site is revoked where these Terms of Use or use of the Site is prohibited and, in such circumstances, you agree not to use or access the Site or Services in any way.</p>
      <h3>USER CONTENT POSTED ON THE SITE</h3>
      <p>You own all of the content and information you post on ShopMyFeed. In addition, for content that is covered by intellectual property rights, like photos, you specifically give us the following permission: you grant us a non-exclusive, transferable, sub-licensable, royalty-free, worldwide license to use any IP content that you post on or in connection with ShopMyFeed ("IP License").</p>
      <h3>PROHIBITED USES</h3>
      <p>You may not use the ShopMyFeed site and or its services to transmit any content which:</p>
      <ul>
      <li>harasses, threatens, embarrasses or causes distress, unwanted attention or discomfort upon any other person,</li>
      <li>includes sexually explicit images or other content which is offensive or harmful to minors,</li>
      <li>includes any unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, or hateful material, including but not limited to material based on a person's race, national origin, ethnicity, religion, gender, sexual orientation, disablement or other such affiliation,</li>
      <li>impersonates any person or the appearance or voice of any person,</li>
      <li>utilizes a false name or identity or a name or identity that you are not entitled or authorized to use,</li>
      <li>contains any unsolicited advertising, promotional materials, or other forms of solicitation,</li>
      <li>contravenes any application law or government regulation,</li>
      <li>violates any operating rule, regulation, procedure, policy or guideline of ShopMyFeed as published on the ShopMyFeed website,</li>
      <li>may infringe the intellectual property rights or other rights of third parties, including trademark, copyright, trade secret, patent, publicity right, or privacy right, or</li>
      <li>distributes software or other Content in violation of any license agreement.</li>
      </ul>
      <h3>USE OF CONTENT ON EXTERNAL SITES</h3>
      <p>You understand that if you use a ShopMyFeed photo on an external web site, then the photo must link back to its ShopMyFeed page on ShopMyFeed.</p>
      <h3>NO WARRANTY AND LIMITATION OF LIABILITY</h3>
      <p>ShopMyFeed PROVIDES THE SITE AND SERVICES "AS IS" AND WITHOUT ANY WARRANTY OR CONDITION, EXPRESS, IMPLIED OR STATUTORY. ShopMyFeed SPECIFICALLY DISCLAIMS ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, INFORMATION ACCURACY, INTEGRATION, INTEROPERABILITY OR QUIET ENJOYMENT. Some states do not allow the disclaimer of implied warranties, so the foregoing disclaimer may not apply to you.</p>
      <p>You understand and agree that you use the Site and Services at your own discretion and risk and that you will be solely responsible for any damages that arise from such use. UNDER NO CIRCUMSTANCES SHALL ShopMyFeed BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES OF ANY KIND, OR ANY OTHER DAMAGES WHATSOEVER (HOWEVER ARISING, INCLUDING BY NEGLIGENCE), INCLUDING WITHOUT LIMITATION, DAMAGES RELATED TO USE, MISUSE, RELIANCE ON, INABILITY TO USE AND INTERRUPTION, SUSPENSION, OR TERMINATION OF THE SITE OR SERVICES, DAMAGES INCURRED THROUGH ANY LINKS PROVIDED ON THE SITE AND THE NONPERFORMANCE THEREOF AND DAMAGES RESULTING FROM LOSS OF USE, SALES, DATA, GOODWILL OR PROFITS, WHETHER OR NOT ShopMyFeed HAS BEEN ADVISED OF SUCH POSSIBILITY. YOUR ONLY RIGHT WITH RESPECT TO ANY DISSATISFACTION WITH THIS SITE OR SERVICES OR WITH ShopMyFeed SHALL BE TO TERMINATE USE OF THIS SITE AND SERVICES. Some countries or states do not allow the exclusion of liability for incidental or consequential damages, so the above exclusions may not apply to you.</p>
      <h3>OTHER</h3>
      <p>ShopMyFeed, in its sole discretion, may terminate your membership and remove and discard any information associated with the membership with or without notice. ShopMyFeed will not be liable to you for termination of your membership to the Service.</p>
      <p>ShopMyFeed, in its sole discretion, may delete any of the content posted to the Site and remove and discard any information associated with the content with or without notice. ShopMyFeed will not be liable to you for deletion of the images.</p>
      <p>ShopMyFeed reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. You agree that ShopMyFeed shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</p>
      <p>ShopMyFeed Terms of Service as noted above may be updated by us from time to time without notice to you. In addition, when using particular ShopMyFeed owned or operated services, you and ShopMyFeed shall be subject to any posted guidelines or rules applicable to such services, which may be posted from time to time.</p>
    </div>
    </div>
    </div>
  )
}

export default TermsAndConditions;