export default class ConfirmEmailService {
  update(pinCode) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/email_confirmation/${pinCode}`, {
      method: "PATCH",
      body: JSON.stringify({}),
      headers: new Headers({
        "Content-Type": "text/plain"
      }),
    }).then((res) => {
      return res.json();
    });
  }

  resend(email) {
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/resend_email_confirmation`, {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: new Headers({
        "Content-Type": "application/json"
      }),
    }).then((res) => {
      return res.json();
    });
  }
}