import store from "../../store"
import produce from "immer"

export const openProfileEditor = (value) => {
  const profileEditorState = store.getState().profileEditorState

  const nextState = produce(profileEditorState, draftState => {
    draftState['isOpen'] = value
  })

  store.dispatch({ type: 'profileEditorState', payload: nextState })
}