import { useState } from "react";
import Footer from "../../Footer";
import Colors from "../../shared/Colors";
import ColorPicker from "../../shared/ColorPicker";
import Font from "../../shared/Font";
import { updateColor } from "./UpdateColor";
import { updateFont } from "./UpdateFont";
import { updateColorFont } from "./UpdateColorFont";
import currentLinkEditor from "../shared/CurrentLinkEditor";
import displayEditor from "../../shared/DisplayEditor";
import store from "../../../store";

const StyleEditor = (props) => {
  const profileSettingChangesState = store.getState().profileSettingChangesState
  const [currentTab, setCurrentTab] = useState("fontTab")

  const underLineTab = (tab) => {
    if (currentTab === tab) {
      return { borderBottomWidth: "1px", borderBottomStyle: "solid" } 
    } else {
      return {}
    } 
  }

  return(
    <div className="center">
      <div className="flex justify-center border-bottom-solid border-grey">
        <div className="flex justify-around w100">
          <div className="w33" style={underLineTab("fontTab")} onClick={() => setCurrentTab("fontTab")}>
            <small className="text-grey-xdark flex justify-center">Font</small>
          </div>
          <div className="w33" style={underLineTab("colorTab")} onClick={() => setCurrentTab("colorTab")}>
            <small className="text-grey-xdark flex justify-center">Color</small>
          </div>
          <div className="w33" style={underLineTab("colorPickerTab")} onClick={() => setCurrentTab("colorPickerTab")}>
            <small className="text-grey-xdark flex justify-center">Picker</small>
          </div>
        </div>
      </div>

      { currentTab === "fontTab" && <Font updateService={updateFont}/> }
      { currentTab === "colorTab" && <Colors updateService={updateColorFont}/> }
      { currentTab === "colorPickerTab" && <ColorPicker updateService={updateColorFont} defaultColor={profileSettingChangesState.button_css?.backgroundColor}/> }

      <Footer
        back={() => {
          displayEditor("LinkTemplates", [140, 140, 0, 0], 0)
          currentLinkEditor("EditorTypes")
        }}
        name="Font Editor" 
        reset={false}
      />
    </div>
  )
}

export default StyleEditor
