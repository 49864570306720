import { Redirect, Route } from 'react-router-dom';
import { IonReactRouter } from '@ionic/react-router';
import {
  IonRouterOutlet,
  IonTabs,
} from '@ionic/react';


import Login from '../pages/public/Login';
import SignUp from '../pages/public/SignUp';
import ConfirmEmail from '../pages/public/ConfirmEmail';
import ResendConfirmEmail from '../pages/public/ResendConfirmationEmail';
import ResetPassword from '../pages/public/ResetPassword';
import UpdatePassword from '../pages/public/UpdatePassword';
import PrivacyPolicy from '../pages/public/PrivacyPolicy';
import TermsAndConditions from '../pages/public/TermsAndConditions';
import { Switch } from "react-router";

import Links from '../pages/public/profile/Links';
import ShoppingFeed from '../pages/public/profile/ShoppingFeed';
import Products from '../pages/public/profile/Products';
import ShopMedia from '../pages/ShopMedia';

const PublicRoutes = () => {
  return(
    <IonRouterOutlet id="menuContent" animated={false}>
      <Switch>
        <Route path="/pages/privacy_policy">
          <PrivacyPolicy />
        </Route>
        <Route path="/pages/terms_and_conditions">
          <TermsAndConditions />
        </Route>
        <Route path="/update_password">
          <UpdatePassword />
        </Route>
        <Route path="/confirm_email">
          <ConfirmEmail />
        </Route>
        <Route path="/resend_confirm_email">
          <ResendConfirmEmail />
        </Route>
        <Route path="/reset_password">
          <ResetPassword />
        </Route>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route exact path="/signup">
          <SignUp />
        </Route>
        <Route path="/login" component={Login} />
        <Route path="/medias/:id" component={(props) => <ShopMedia {...props.match.params} />} />
        <Route exact path="/:slug" component={Links} />
        <Route path="/:slug/images" component={ShoppingFeed} /> 
        <Route path="/:slug/products" component={Products} /> 
      </Switch>
    </IonRouterOutlet>
  )  
}

export default PublicRoutes