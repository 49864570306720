
import { IonIcon } from '@ionic/react';
import { arrowBackOutline } from 'ionicons/icons';

const Footer = (props) => {
  return(
    <div className="border-grey-light border-top-solid py2">
      <div className="center" style={{ width: "80%" }}>
        <div className="flex justify-between px1 items-center">
          <div className="w33" onClick={() => props.back()}>
            <IonIcon 
              className="flex items-center text-grey-xdark"
              style={{ color: "grey", width: "20px", height: "20px"}}
              ios={arrowBackOutline} 
              md={arrowBackOutline}  
            />
          </div>

          <small 
            className="w33 text-grey-xdark center-align" 
            onClick={() => props.back()}
          >
            { props.name }
          </small>

          { 
            props.reset && <small className="flex justify-end w33 text-blue bold" onClick={() => props.reset()}>
              Reset
            </small>
          }

          { 
            !props.reset && <small className="flex justify-end w33 text-blue bold" />
          }
        </div>
      </div>
    </div>
  )
}

export default Footer
