import { useState } from 'react';
import Templates from './Templates'
import StyleEditor from "./update/StyleEditor";
import StandardFontEditor from "./update/FontEditor";
import EditorTypes from "./EditorTypes";
import store from "../../store";

const Base = (props) => {
  const [currentLinkEditor, setCurrentLinkEditor] = useState("Templates")

  store.subscribe(() => {
    const editor = store.getState().profileEditorState.currentLinkEditor
    const currentLinkEditor =  editor === undefined ? "Templates" : editor

    setCurrentLinkEditor(currentLinkEditor)
  })

  return(
    <div>
      { currentLinkEditor === "Templates" && <Templates/> }
      { currentLinkEditor === "StyleEditor" && <StyleEditor/> }
      { currentLinkEditor === "StandardFontEditor" && <StandardFontEditor/> }
      { currentLinkEditor === "EditorTypes" && <EditorTypes/> }
    </div>
  )
}

export default Base
