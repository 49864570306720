import { useState, useEffect } from 'react';
import { useIonRouter, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonInput, IonItem, IonLabel } from '@ionic/react';
import { } from "../../images/instagram.svg";
import ConfirmEmailService from '../../components/services/ConfirmEmailService';
import store from "../../components/store";
import { BrowserRouter as Router, Link } from "react-router-dom"

const ResendConfirmEmail = () => {
  const confirmEmailService = new ConfirmEmailService()
  const router = useIonRouter();
  const [email, setEmail] = useState([])
  const [errors, setErrors] = useState([])


  const resendEmail = async () => {
    const res  = await confirmEmailService.resend(email)

    if(res.errors) {
      setErrors(res.errors)
    } else {
      router.push(`/confirm_email`);
    }
  }

  return (
    <IonPage>
      <div>
        <div className="flex-auto">
          <div className="flex justify-center pt3">
            <Link to={`/login`}>
              <img className="max-w250px" src="assets/images/logo_black.png" />
            </Link>
          </div>
          <div className="flex justify-center">
            <div className="form-half p2 max-w500px">
              <div className="bg-white rounded-large">
                <div className="flex flex-column p4">
                  <h1 className="font-brand text-brand-blue-dark">Resend Email Confirmation</h1>
                  <form novalidate="novalidate" class="simple_form new_user">
                    <div className="form-inputs">
                      <div className="mb2">
                        <label class="control-label">Email</label>
                        <input class="form-control password optional" type="email" onChange={(e) => setEmail(e.target.value)} />
                      </div>
                    </div>
                  </form>
                  <button className="button button-brand-brown w100 mt2" onClick={(e) => resendEmail()}>Resend</button>
                  
                  {
                    errors.length > 0 && <div className="flex items-baseline border-full border-solid rounded mt3 border-red">
                      <p className="text-red pt2 px2">{errors}</p>
                    </div>
                  }

                  <div className="pt3 flex">
                    <Link to={`/login`}>
                      <p className="text-blue">Already have an account?</p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default ResendConfirmEmail;
