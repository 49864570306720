import store from "../store";
import InstagramService from "../services/external_identities/InstagramService";
import { useIonRouter } from '@ionic/react';
import { useEffect } from 'react';
import { ClipLoader } from "react-spinners";

const ConnectInstagram = (props) => {
  const instagramService = new InstagramService()
  const authToken = store.getState().authTokenState
  const router = useIonRouter();
  useEffect(() => connectInstagram(), [] )

  const connectInstagram = async () => {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const token = urlParams.get('instagram_token')

    const res = await instagramService.update(authToken, token)
    
    if(res.errors) {
      console.log('connectInstagram error', res)
    } else {
      await store.dispatch({ type: 'userState', payload: res })
      router.push(`/${res.slug}/images`);
    }
  }


  return(
    <div className="flex justify-center pt4">
      <div className="bg-white rounded p2 border-full border-grey" >
        <p className="bold center-align">Please while we connect Instagram</p>
        <div className="flex justify-center pb2">
          <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
            <ClipLoader color={"#000000"}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConnectInstagram