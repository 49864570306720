import { IonContent, IonPage } from '@ionic/react';
import UserProductsWrapper from "../../components/products/UserProductsWrapper";
import ProfileWrapper from '../../components/users/ProfileWrapper';
import MainHeader from '../../components/headers/main_header';
import store from '../../components/store';

const Products: React.FC = () => {
  return (
    <IonPage>
      <MainHeader />
      <IonContent fullscreen>
        <ProfileWrapper 
          styles={ store.getState().styles } 
          body={<UserProductsWrapper />}
        />
      </IonContent>
    </IonPage>
  );
};

export default Products;
