import store from "../store";
import { useState } from 'react';
import { useIonRouter } from '@ionic/react';

const ProfileHeader = (props) => {
  const isOpen = store.getState().profileEditorState.isOpen
  const [styles, setStyles] = useState(isOpen ? store.getState().profileSettingChangesState : store.getState().styles)
  const headerBackgroundCss = styles.header_background_css
  const headerImageUrl = styles.header_image_url
  const user = store.getState().userState
  const router = useIonRouter();
  const linksUrl = `/${user.slug}`;
  const shopFeedUrl = `/${user.slug}/images`;
  const productsUrl = `/${user.slug}/products`;
  const underlineColor = (styles.tabs_css === undefined || styles.tabs_css === null) ? "black" : styles.tabs_css.color
  const underline = { borderBottom: `1px solid ${underlineColor}`};

  store.subscribe(() => {
    setStyles(isOpen ? store.getState().profileSettingChangesState : store.getState().styles)
  })

  const styleTabs = (url) => {
    if (window.location.pathname === url) {
      return { ...styles.tabs_css, ...underline }
    } else {
      return styles.tabs_css
    }
  }

  const styleLinkTab = () => {
    if (window.location.pathname === linksUrl || window.location.pathname === "/profile_editor") {
      return { ...styles.tabs_css, ...underline }
    }
  }

  const showHeader = headerImageUrl !== null || (headerBackgroundCss !== null)

  return (
    <div className="pb3">
      <div className="container-no-padding max-w800px relative" >
        {
          showHeader &&
          <div>
            <div className="container-no-padding">
              <div className="absolute w100 max-w800px" >
                <div className="absolute r0 z2 w100" >
                  {
                    headerBackgroundCss !== null && <div style={headerBackgroundCss} className="z1 profile-header-image" />
                  }
                  {
                    headerImageUrl !== null && <img src={headerImageUrl} className="z1 profile-header-image" />
                  }
                </div>
              </div>
            </div>
            <div className="relative" style={{height: "236px"}} >
              <div className="absolute bottom-0 w100">
                <div className="flex justify-center" >
                  {}
                  <img src={styles.avatar_image_url} className="z2 header-avatar" />
                </div>
                <div className="font-large extra-bold font-brand center-align bold pb1 pt2" style={styles.username_css} >
                  {user.username}
                </div>
              </div>
            </div>
          </div>
        }

        {
          !headerImageUrl && !headerBackgroundCss && <div className="relative pt3" >
            <div className="flex justify-center" >
              <img src={styles.avatar_image_url} className="z2 header-avatar center" />
            </div>
            <div className="flex justify-center" >
              <div className="font-large extra-bold font-brand bold pb1 pt2" style={styles.username_css} >
                {user.username}
              </div>
            </div>
          </div>
        }

        <div className="flex justify-center pl1">
          <div>
            <p className="mb0" onClick={() => router.push(linksUrl)} style={styleLinkTab()}>Links</p>
          </div>
          <div className="px3">
            <p className="mb0" onClick={() => router.push(shopFeedUrl)} style={styleTabs(shopFeedUrl)}>Shop Feed</p>
          </div>
          <div>
            <p className="mb0"  onClick={() => router.push(productsUrl)} style={styleTabs(productsUrl)}>Products</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileHeader