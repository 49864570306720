const RankList = (props) => {
  const ranks = props.ranks;

  return (
    <div className="py2" >
      <table style={{ border: "none" }}>
        <tr>
          <th className="center-align pb3 text-grey-xdark" style={{ border: "none", fontSize: "1.3rem", backgroundColor: "#f7f7f7" }}>RANK</th>
          <th className="px3 pb3 text-grey-xdark" style={{ border: "none", fontSize: "1.3rem", backgroundColor: "#f7f7f7"  }}>LINK</th>
          <th className="pb3 text-grey-xdark" style={{ border: "none", fontSize: "1.3rem", backgroundColor: "#f7f7f7"  }}>CLICKS</th>
        </tr>

        { 
          ranks.map((rank, index) => (
            <tr>
              <td className="center-align pb2 bold" style={{ border: "none", color: "#ED8340", backgroundColor: "#f7f7f7" }}>{index + 1}</td>
              <td className="px3 pb2 text-grey-xdark" style={{ border: "none", backgroundColor: "#f7f7f7" }}>{rank.title}</td>
              <td className="pb2 bold text-green" style={{ border: "none", backgroundColor: "#f7f7f7" }}>{rank.clicks}</td>
            </tr>
          ))
        }
      </table>
    </div>
  )
}

export default RankList