import { IonContent, IonPage } from '@ionic/react';
import Medias from '../../../components/medias/Medias';
import ProfileWrapper from '../../../components/users/ProfileWrapper';
import { useEffect, useState } from 'react';
import store from '../../../components/store';
import UserService from '../../../components/services/UserService';
import { ClipLoader } from "react-spinners";

const ShoppingFeed: React.FC = (props) => {
  const userService = new UserService()
  const user = store.getState().userState
  const [slug, setSlug] = useState(props.match?.params?.slug)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    fetchUser()
  })

  const fetchUser = async () => {
    if (user === null || store.getState().styles === null) {
      const res = await userService.show(slug)
      store.dispatch({ type: 'userState', payload: res.user }) 
      store.dispatch({ type: 'stylesState', payload: res.styles })
    }

    setLoading(false)
  }

  return (
    <IonPage>
      <IonContent fullscreen>
      {
          loading && <div className="flex justify-center pb2">
            <div className="bg-white p1" style={{ borderRadius: "40px", display: "inherit" }}>
              <ClipLoader color={"#000000"} loading={loading}  />
            </div>
          </div>
        }

        {
          !loading && <ProfileWrapper body={<Medias />} styles={ store.getState().styles }/>
        }
      </IonContent>
    </IonPage>
  );
};

export default ShoppingFeed;
