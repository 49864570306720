import Base from './Base';

const Shadow = (props) => {
  return(
    <Base 
      params={{ 
        backgroundColor: "black",
        boxShadow: "#777777 4px 4px 4px"
      }}
    />
  )
}

export default Shadow
