import { configureStore, combineReducers } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
const initialState = {};


function stylesReducer(state = initialState, action) {
  // Check to see if the reducer cares about this action
  if (action.type === 'stylesState') {
    // If so, make a copy of `state`
    return {
      ...state,
      // and update the copy with the new value
      ...action.payload
    };
  }
  // otherwise return the existing state unchanged
  return state
}

function profileSettingPrototypesReducer(state = [], action) {
  if (action.type === 'profileSettingPrototypes') {
    return action.payload || state
  }
  return state
}

function profileSettingChangesReducer(state = {}, action) {
  if (action.type === 'profileSettingChangesState') {
    return action.payload || state
  }
  return state
}


function mediasReducer(state = [], action) {
  switch(action.type) {
    case 'mediasState':
      return action.payload || state
    default:
      return state
  }
}

function productsReducer(state = [], action) {
  switch(action.type) {
    case 'productsState':
      return action.payload || state
    default:
      return state
  }
}

function userReducer(state = null, action) {
  switch(action.type) {
    case 'userState':
      return action.payload || state
    default:
      return state
  }
}

function buttonReducer(state = null, action) {
  switch(action.type) {
    case 'buttonState':
      return action.payload || state
    default:
      return state
  }
}

function authTokenStateReducer(state = null, action) {
  if (action.type === 'authTokenState') {
    return action.payload || state
  }
  return state
}

function profileEditorReducer(state = { isOpen: false }, action) {
  switch(action.type) {
    case 'profileEditorState':
      return action.payload || state
    default:
      return state
  }
}

const reducers = combineReducers({
  styles: stylesReducer,
  profileSettingChangesState: profileSettingChangesReducer,
  profileSettingPrototypes: profileSettingPrototypesReducer,
  mediasState: mediasReducer,
  userState: userReducer,
  productsState: productsReducer,
  buttonState: buttonReducer,
  authTokenState: authTokenStateReducer,
  profileEditorState: profileEditorReducer,
})

const persistConfig = {
  key: 'root',
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);


export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});
