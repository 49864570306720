export default class MediaProductService {
  index(page, media_id, withOutMedia, user_media_uuid) {
    const params = withOutMedia
      ? `page=${page}&without_media=true&user_uuid=${user_media_uuid}`
      : `page=${page}&user_uuid=${user_media_uuid}`;
    return fetch(`${process.env.REACT_APP_API_HOST}/api/v1/medias/${media_id}/products?${params}`, {
      method: "GET",
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    }).then((res) => {
      return res.json();
    });
  }
}
