import React from "react";
import {
  IonMenu,
  IonContent,
  IonList,
  IonItem,
  IonIcon,
  useIonRouter,
  IonLabel,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import { settingsOutline, logOutOutline } from "ionicons/icons";
import store from "../store";

const Menu: React.FC = () => {
  const router = useIonRouter();

  const onClickHandler = (): void => {
    menuController.close();
  };

  const logOut = () => {
    menuController.close();

    store.dispatch({ type: 'userState', payload: {}})
    store.dispatch({ type: 'authTokenState', payload: {}})
    store.dispatch({ type: 'productsState', payload: {}})
    store.dispatch({ type: 'mediasState', payload: {}})
    store.dispatch({ type: 'styles', payload: {}})

    router.push(`/login`);
  }

  return (
    <React.Fragment>
      <IonMenu
        side="start"
        menuId="first"
        content-id="menuContent"
        swipe-gesture={true}
        disabled={false}
        maxEdgeStart={100}
        hidden={false}
        type="overlay">
        <IonContent>
          <IonList className="mt15">
            <IonItem onClick={() => {
              menuController.close();
              router.push(`/account`)
            }}>
              <IonIcon ios={settingsOutline} md={settingsOutline} slot="start"></IonIcon>
              <IonLabel>Account</IonLabel>
            </IonItem>
            <IonItem onClick={() => logOut()}>
              <IonIcon ios={logOutOutline} md={logOutOutline} slot="start"></IonIcon>
              <IonLabel>Log out</IonLabel>
            </IonItem>
          </IonList>
        </IonContent>
      </IonMenu>
    </React.Fragment>
  );
};

export default Menu;