import { IonIcon, IonButtons, IonHeader, IonToolbar } from '@ionic/react';
import { menuOutline, createOutline, openOutline } from 'ionicons/icons';
import { menuController } from "@ionic/core";
import { useIonRouter } from '@ionic/react';
import store from '../store';
import { useState } from 'react';
import { openProfileEditor } from '../editors/shared/OpenProfileEditor';
import { Browser } from '@capacitor/browser';

const MainHeader = (props) => {
  const router = useIonRouter();
  const user = store.getState().userState
  const [displayOnlyHamburger] = useState(props.displayOnlyHamburger === undefined ? false : props.displayOnlyHamburger)

  const openMenu = () => {
    menuController.open()
  }

  const openCapacitorSite = async () => {
    await Browser.open({ url: `${process.env.REACT_APP_API_HOST}/${user.slug}`, presentationStyle: 'popover', windowName: "Your public profile" });
  };

  return(
    <IonHeader className="ion-no-border">
      <IonToolbar>
        <IonButtons slot="start">
          <IonIcon  onClick={() => openMenu()} size="large" color="dark" ios={menuOutline} md={menuOutline} />
        </IonButtons>

        {
          <div className="flex justify-center">
            <div 
              className="bg-grey-light inline-block rounded-large" 
              style={{ borderRadius: "15px", padding: "2px" }}
              onClick={() => openCapacitorSite()}
            >
              <div>
                <div className="flex items-center">
                  <p className="mx2 mb0 text-grey-xxdark">/{user.slug}</p>
                  <IonIcon 
                    className="bg-white p1"
                    style={{ borderRadius: "15px" }}
                    ios={openOutline} 
                    md={openOutline} 
                  />
                </div>
              </div>
            </div>
          </div>
        }

        {
          !displayOnlyHamburger &&
          <IonButtons slot="end">
            <div className="flex pr2">
              <IonIcon 
                className="p2 bg-grey-light" 
                style={{ borderRadius: "10px" }} 
                ios={createOutline} 
                md={createOutline} 
                onClick={() => {
                  openProfileEditor(true)
                  router.push("/profile_editor")
                }}
              />
            </div>
          </IonButtons>
        }
      </IonToolbar>
    </IonHeader>
  )
}

export default MainHeader
