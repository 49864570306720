import { useState } from 'react';
import store from '../../store';

const Font = (props) => {
  const [usernameStyles, setUsernameStyles] = useState(store.getState().username_css);

  store.subscribe(() => {
    setUsernameStyles(store.getState().styles.username_css)
  })

  return(
    <div className="center pt3 profile-editor-tabs">
      <div className="flex justify-between pb3">
        <div className="w33">
          <p>CASE</p>
          <div>
            <select onChange={(event) => { props.updateService({ textTransform: event.target.value }) }}>
              <option value="capitalize">Standard</option>
              <option value="lowercase">lower</option>
              <option value="uppercase">UPPER</option>
            </select>
          </div>
        </div>

        <div className="w33">
          <p>SIZE</p>
          <div>
            <select onChange={(event) => { props.updateService({ fontSize: event.target.value }) }}>
              <option value="16px">standard</option>
              <option value="18px">medium</option>
              <option value="20px">large</option>
              <option value="24px">x-large</option>
            </select>
          </div>
        </div>

        <div className="w33">
          <p>SPACING</p>
          <div>
            <select onChange={(event) => { props.updateService({ letterSpacing: event.target.value }) }}>
              <option value="normal">standard</option>
              <option value="2px">2px</option>
              <option value="4px">4px</option>
              <option value="6px">6px</option>
              <option value="8px">8px</option>
              <option value="10px">10px</option>
              <option value="12px">12px</option>
            </select>
          </div>
        </div>
      </div>

      <p>STYLE</p>
      <div className="flex pb2">
        <div 
          className="w50 mr2 border-full rounded border-grey-dark bg-white" 
          onClick={() => props.updateService({ fontFamily: "FuturaCyrillicBook" })}
        >
          <h3 className="font-brand m0 p2">Standard</h3>
        </div>

        <div 
          className="w50 border-full rounded border-grey-dark bg-white" 
          onClick={() => props.updateService({ fontFamily: "Jost" })}
        >
          <h3 className="font-jost m0 p2">Jost</h3>
        </div>
      </div>

      <div className="flex">
        <div 
          className="w50 mr2 border-full rounded border-grey-dark bg-white" 
          onClick={() => props.updateService({ fontFamily: "Yeseva One" })}
        >
          <h3 className="font-yeseva m0 p2">Yeseva</h3>
        </div>

        <div 
          className="w50 border-full rounded border-grey-dark bg-white" 
          onClick={() => props.updateService({ fontFamily: "DM Serif Display" })}
        >
          <h3 className="font-volkhov m0 p2">Serif</h3>
        </div>
      </div>
    </div>
  )
}

export default Font
