import ProfileHeader from "./ProfileHeader";
import '../../css/styles.css';
import { useState } from 'react';
import store from "../store";

const ProfileWrapper = (props) => {
  const isProfileEditorOpen = props.isOpen !== undefined ? props.isOpen : store.getState().profileEditorState.isOpen
  const [styles, setStyles] = useState(props.styles)

  store.subscribe(() => {
    setStyles(isProfileEditorOpen ? store.getState().profileSettingChangesState : store.getState().styles)
  })

  const bgStyles = () => {
    if (styles.background_image_url !== null) {
      return { background: `url(${styles.background_image_url}) center center / cover no-repeat fixed`, backgroundSize: "cover" }
    } else if (styles.background_css == null) {
      return { backgroundColor: "white" }
    } else  {
      return styles.background_css
    }
  }
  
  return(
    <div 
      className="min-h100"
      style={bgStyles()}
    >
      <ProfileHeader />
      { props.body }
    </div>
  )
}

export default ProfileWrapper