import React from 'react';
import PropTypes from 'prop-types';
var mobile = require("is-mobile");

export default class SimilarProducts extends React.Component {
  constructor() {
    super();

    this.state = {
      isMobile: mobile()
    };
  }

  render() {
    const { isMobile } = this.state;
    const { products } = this.props;
    const cols = isMobile ? [[], []] : [[], [], []];
    const indexCount = isMobile ? 2 : 3;
    const cssColumns = isMobile ? "c1in2" : "c1in3";

    products.map((product, index) => {
      cols[index % indexCount].push(product);
    });

    return (
      <div className="flex flex-wrap justify-between masonry px1 max-w1000px">
        {products.length > 0 &&
          cols.map((col, index) => (
            <div className={`${cssColumns} flex flex-column`} key={index}>
              {col.map(product => (
                <div
                  key={product.uuid}
                  uuid={`product-${product.uuid}`}
                  className="px1"
                >
                  <div className="card flex flex-column relative bg-white rounded-large mb2 border-light-grey">
                    <a href={product.redirect_url} target="_blank">
                      <div className="card-image-container relative">
                        <div className="card-image">
                          <img src={product.image_url} />
                        </div>
                      </div>
                    </a>
                    <div className="px2">
                      <div className="font-small left-align truncate">
                        {product.title}
                      </div>
                      <div className="font-base bold left-align">
                        {product.price} {product.currency_code}
                      </div>
                      <div className="font-small left-align">
                        <a href={product.user.website_url} target="_blank">
                          Sold by: {product.user.username}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    );
  }
}
